import React from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import easyinvoice from 'easyinvoice'
import { CLOTHING, ELECTRONICS, FOOTWEAR, FURNITURE, SPORTS, GROCERY, FOOD, BOOKS, TOYS } from '../constants/categoryConstants'

const GstBillingInvoice = () => {
  const cart = useSelector((state) => state.cart)
  const { shippingAddress } = cart
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const orderDetails = useSelector((state) => state.orderDetails)
  const { order } = orderDetails

  /* ------------company Details---------- */
  const company = 'MPS Pvt'
  const companyAddress = 'Beside GND Engg college'
  const companyCity = 'Bidar'
  const companyCode = '585402'
  const country = 'karnataka India'

  /* --------------client Details----------- */
  const clientName = userInfo.name
  const ClientAdress = shippingAddress.address
  const clientCode = shippingAddress.pinCode
  const clientCity = shippingAddress.city

  /* -------------order information------------ */
  const infoId = order._id
  const date = new Date()
  const infoDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()

  let products = []

  const getGST = (category) => {
    switch (category) {
      case ELECTRONICS:
        return 18
      case CLOTHING:
        return 12
      case GROCERY:
        return 5
      case FOOD:
        return 5
      case FOOTWEAR:
        return 5
      case SPORTS:
        return 12
      case FURNITURE:
        return 12
      case BOOKS:
        return 5
      case TOYS:
        return 12
      default:
        return 5
    }
  }

  order.orderItems.map((item) => {
    let quantity = item.qty
    let GST = getGST(item.category)
    let price = (item.price * 100) / (100 + GST)
    console.log(item.category)

    products.push({ quantity: quantity, description: item.name, price: price.toFixed(2), 'tax-rate': `${GST}` })
    return 0
  })

  /* var html = '<p style="color:green">Hello world! This is invoice number %number%</p>' */

  var data = {
    /*  customize: {
      // btoa === base64 encode
      template: btoa(html), // Your template must be base64 encoded
    },*/
    images: {
      logo: 'https://public.easyinvoice.cloud/img/logo_en_original.png',
    },
    sender: {
      company: company,
      address: companyAddress,
      city: companyCity,
      zip: companyCode,
      country: country,
    },
    client: {
      company: clientName,
      address: ClientAdress,
      zip: clientCode,
      city: clientCity,
      country: country,
    },

    information: {
      number: infoId,
      date: infoDate,
    },
    products: products,
    'bottom-notice': 'Thank you for Shopping',
    settings: {
      currency: 'INR',
      'tax-notation': 'GST',
      'margin-top': 25,
      'margin-right': 25,
      'margin-left': 25,
      'margin-bottom': 10,
    },
  }

  const downloadInvoice = async () => {
    const datas = data
    easyinvoice.createInvoice(datas, function (result) {
      easyinvoice.download('myInvoice.pdf', result.pdf)
    })
  }

  return (
    <>
      <Button className='textSizeM' variant='secondary' onClick={downloadInvoice}>
        download GST Invoice
      </Button>
    </>
  )
}

export default GstBillingInvoice
