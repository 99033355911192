import axios from 'axios'
import env from 'dotenv'
import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CSV_CREATE_SUCCESS,
  PRODUCT_CREATE_FAIL,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_CREATE_REVIEW_FAIL,
  PRODUCT_TOP_REQUEST,
  PRODUCT_TOP_SUCCESS,
  PRODUCT_TOP_FAIL,
  PRODUCT_FINISHING_STOCK_REQUEST,
  PRODUCT_FINISHING_STOCK_SUCCESS,
  PRODUCT_FINISHING_STOCK_FAIL,
} from '../constants/productConstants'

env.config()
axios.defaults.baseURL = process.env.REACT_APP_API_SERVER
const storeId = process.env.REACT_APP_STORE_ID

export const listAllProducts =
  (keyword = '', pageNumber = '') =>
    async (dispatch, getState) => {
      try {
        console.log(keyword);
        dispatch({ type: PRODUCT_LIST_REQUEST })

        const {
          userLogin: { userInfo },
        } = getState()

        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
        if (keyword) {
          const { data } = await axios.get(
            `/api/store/${storeId}/products/all?keyword=${keyword}&pageNumber=${pageNumber}`,
            config
          )
          dispatch({
            type: PRODUCT_LIST_SUCCESS,
            payload: data,
          })
        } else {
          //step : 2 request data from  api server
          const { data } = await axios.get(`/api/store/${storeId}/products/all?pageNumber=${pageNumber}`, config)
          //step : 3 dispatch data to reducer
          dispatch({
            type: PRODUCT_LIST_SUCCESS,
            payload: data,
          })
        }
      } catch (error) {
        dispatch({
          type: PRODUCT_LIST_FAIL,
          payload: error.response.data.message ? error.response.data.message : error.message,
        })
      }
    }

export const listProducts =
  (keyword = '', category = '', subCategory = '', pageNumber = '') =>
    async (dispatch) => {
      try {
        dispatch({ type: PRODUCT_LIST_REQUEST })

        if (subCategory !== '') {
          const { data } = await axios.get(`/api/store/${storeId}/products/subcategory/${subCategory}?pageNumber=${pageNumber}`)
          dispatch({
            type: PRODUCT_LIST_SUCCESS,
            payload: data,
          })
        } else if (category !== '') {

          const { data } = await axios.get(`/api/store/${storeId}/products/category/${category}?pageNumber=${pageNumber}`)
          dispatch({
            type: PRODUCT_LIST_SUCCESS,
            payload: data,
          })
        } else {
          //step : 2 request data from  api server
          const { data } = await axios.get(`/api/store/${storeId}/products/?keyword=${keyword}&pageNumber=${pageNumber}`)
          //step : 3 dispatch data to reducer
          dispatch({
            type: PRODUCT_LIST_SUCCESS,
            payload: data,
          })
        }
      } catch (error) {
        dispatch({
          type: PRODUCT_LIST_FAIL,
          payload: error.response.data.message ? error.response.data.message : error.message,
        })
      }
    }

export const listProductDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST })

    const { data } = await axios.get(`/api/store/${storeId}/products/${id}`)

    dispatch({
      type: PRODUCT_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload: error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const deleteProduct = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    await axios.delete(`/api/store/${storeId}/products/${id}`, config)
    dispatch({
      type: PRODUCT_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_DELETE_FAIL,
      payload: error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const createSampleProduct = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(`/api/store/${storeId}/products/sample`, {}, config)
    dispatch({
      type: PRODUCT_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_CREATE_FAIL,
      payload: error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const createProduct = (product) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(`/api/store/${storeId}/products/`, product, config)
    dispatch({
      type: PRODUCT_CSV_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_CREATE_FAIL,
      payload: error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const updateProduct = (product) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.put(`/api/store/${storeId}/products/${product._id}`, product, config)
    dispatch({
      type: PRODUCT_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_UPDATE_FAIL,
      payload: error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const createProductReview = (productId, reviews) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_CREATE_REVIEW_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    await axios.post(`/api/store/${storeId}/products/${productId}/reviews`, reviews, config)
    dispatch({
      type: PRODUCT_CREATE_REVIEW_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_CREATE_REVIEW_FAIL,
      payload: error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const listTopProducts = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_TOP_REQUEST })

    const { data } = await axios.get(`/api/store/${storeId}/products/top`)

    dispatch({
      type: PRODUCT_TOP_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_TOP_FAIL,
      payload: error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const finishing = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_FINISHING_STOCK_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/store/${storeId}/products/finishingstock`, config)

    dispatch({
      type: PRODUCT_FINISHING_STOCK_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_FINISHING_STOCK_FAIL,
      payload: error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
