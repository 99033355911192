import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { finishing } from '../actions/productActions'
import { useDispatch, useSelector } from 'react-redux'
import Alert from 'react-bootstrap/Alert'

export const LessTenProducts = ({ history }) => {
  const dispatch = useDispatch()

  const finishingSt = useSelector((state) => state.finishingStocks)
  const { finishingStocks, loading, error } = finishingSt

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo && userInfo.role === 'admin') {
      dispatch(finishing())
    } else {
      history.push('/login')
    }
  }, [dispatch, history, userInfo])

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='warning'>{error}</Message>
      ) : (
        <>
          <p className='DashboardHeading'>Stock Alert</p>
          <Row>
            <Col className='text-center' style={{ color: 'black' }} md={8}>
              <h5>Product Name </h5>
            </Col>
            <Col className='text-center' style={{ color: 'black' }}>
              <h5> Qty</h5>
            </Col>
          </Row>
          {finishingStocks.map((stock) => (
            <Alert variant={stock.stockAvailable < 5 ? 'warning' : 'light '} style={{ padding: '0' }}>
              <Row key={stock._id}>
                <Col md={8}>
                  <h5 className='text-center' style={{ color: 'black' }}>
                    {stock._id}
                  </h5>
                </Col>
                <Col>
                  <h5 className='text-center' style={{ color: 'black' }}>
                    {stock.stockAvailable}
                  </h5>
                </Col>
              </Row>
            </Alert>
          ))}
        </>
      )}
    </>
  )
}
