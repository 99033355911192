import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import Card from 'react-bootstrap/Card'
import { useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'


import axios from 'axios'

ChartJS.register(ArcElement, Tooltip, Legend)

export function OrdersSummary() {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const [ordersSummary, setOrdersSummary] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')





  useEffect(() => {
    
  const groupBy = 'day'
  // Date time 12 AM IST current date
  const from = new Date(new Date().setHours(0, 0, 0, 0)).toISOString()
      const config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  }
    axios
      .get(`/api/orders/report?groupBy=${groupBy}&from=${from}`, config)
      .then((res) => {
        console.log(res.data)
        setOrdersSummary(res.data)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setError(err)
      })
  } ,[userInfo.token])

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant='warning'>{error}</Message>
  ) : (
      <>
        <p className='DashboardHeading'> Summary</p>
        <Card className=' mb-2 mt-5' style={{height:'70%', background: 'linear-gradient(to top right, #2aa198 -16%, #ffffff 31%)' }}>
          <Card.Body>
            <Card.Title>
              <h4 style={{textAlign:'center',color: '#2aa198' }}> Daily Sales</h4>
            </Card.Title>
            <Card.Text>
              <h5>Amount : ₹{ordersSummary.map((summary) => summary.totalPrice)}</h5>
            </Card.Text>
            <Card.Text>
              <h6>Daily Orders : {ordersSummary.map((summary) => summary.orders)}</h6>
            </Card.Text>
            <Card.Text>
              <h6>Daily Items : {ordersSummary.map((summary) => summary.items)}</h6>
            </Card.Text>
            <Card.Text>
              <h6>Daily Quantity : {ordersSummary.map((summary) => summary.quantity)}</h6>
            </Card.Text>
          </Card.Body>
        </Card>
      </>
    )
  }

