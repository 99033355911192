import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Form, Button, } from 'react-bootstrap';
import { useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import axios from 'axios'
import env from 'dotenv'

env.config()
axios.defaults.baseURL = process.env.REACT_APP_API_SERVER
const store = process.env.REACT_APP_STORE_ID
const InvoiceCreateScreen = () => {

	const [inovoiceNumber, setInvoiceNumber] = useState('')
	const [invoiceTotal, setInvoiceTotal] = useState()
	const [invoicePaid, setInvoicePaid] = useState('')
	const [invoicePaidDate, setInvoicePaidDate] = useState('')
	const [invoicePaidAmount, setInvoicePaidAmount] = useState()
	const [inovoiceDate, setInvoiceDate] = useState('')
	const [inovoiceDueDate, setInvoiceDueDate] = useState('')

	const [shippingPrice, setShippingPrice] = useState()
	const [shippingDetails, setShippingDetails] = useState('')

	/* --------------------vendor details------------- */
	const [vendorName, setVendorName] = useState('')
	const [vendorPhone, setVendorPhone] = useState('')
	const [vendorAddress, setVendorAddress] = useState('')
	const [vendorCity, setVendorCity] = useState('')
	const [vendorPinCode, setVendorPinCode] = useState('')
	const [vendorCountry, setVendorCountry] = useState('')
	const [vendorEmail, setVendorEmail] = useState('')
	const [vendorContactNote, setVendorContactNote] = useState('')

	/* ----------------------GST Details------------------- */
	const [gstNumber, setGstNumber] = useState('')
	const [gstType, setGstType] = useState('')
	const [gstRate, setGstRate] = useState()
	const [gstAmount, setGstAmount] = useState()

	/* ----------------Invoice Details--------------------- */
	const [invoiceName, setInvoiceName] = useState('')
	const [invoiceQuantity, setInvoiceQuantity] = useState()
	const [invoicePrice, setInvoicePrice] = useState()
	const [invoiceItemTotal, setInvoiceItemTotal] = useState()

	/* ----------------------Payments Details--------------- */
	const [paymentId, setPaymentId] = useState('')
	const [paymentMethod, setPaymentMethod] = useState('')
	const [paymentAmount, setPaymentAmount] = useState()
	const [paymentStatus, setPaymentStatus] = useState('')
	const [paymentTime, setPaymentTime] = useState('')
	const [paymentEmail, setPaymentEmail] = useState('')



	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const productDetails = useSelector((state) => state.productDetails)
	const { loading, error } = productDetails

	const productUpdate = useSelector((state) => state.productUpdate)
	const { loading: loadingUpdate, error: errorUpdate } = productUpdate

	const config = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${userInfo.token}`,
		},
	}

	const submitHandler = (e) => {
		e.preventDefault()
		const data = {
			"invoiceNumber": inovoiceNumber,
			"invoiceItems": [{
				"name": invoiceName,
				"itemQuantity": invoiceQuantity,
				"itemPrice": invoicePrice,
				"itemTotal": invoiceItemTotal,
			}],
			"payments": [{
				"paymentId": paymentId,
				"paymentMethod": paymentMethod,
				"Amount": paymentAmount,
				"status": paymentStatus,
				"updateTime": paymentTime,
				"emailAddress": paymentEmail,
			}],
			"invoiceTotal": invoiceTotal,
			"invoicePaid": invoicePaid,
			"invoicePaidDate": invoicePaidDate,
			"invoicePaidAmount": invoicePaidAmount,
			"invoiceDate": inovoiceDate,
			"invoiceDueDate": inovoiceDueDate,
			"vendorDetails": {
				"name": vendorName,
				"phone": vendorPhone,
				"address": vendorAddress,
				"city": vendorCity,
				"pinCode": vendorPinCode,
				"country": vendorCountry,
				"email": vendorEmail,
				"vendorContactNotes": vendorContactNote,
			},

			"gst": {
				"gstNumber": gstNumber,
				"gstType": gstType,
				"gstRate": gstRate,
				"gstAmount": gstAmount,
			},
			"shippingPrice": shippingPrice,
			"shippingDetails": shippingDetails,
		}
		axios.post(`/api/invoice/?store=${store}`, data, config)
		clearImmediate();
	}


	return (
		<div>
			{loadingUpdate && <Loader />}
			{errorUpdate && <Message variant='warning'>{errorUpdate}</Message>}
			{loading ? (
				<Loader />
			) : error ? (
				<Message variant='warning'>{error}</Message>
			) : (
				<>
					<Button variant='secondary'>
						<Link to='/admin/invoices' style={{ color: "white" }}>Go Back</Link>
					</Button>
					<Form onSubmit={submitHandler}>
						<Row className="mb-1">
							<Col>
								<h4 style={heading}>Invoice Details</h4>
								<Col >
									<Form.Group controlId="invoiceId">
										<Form.Label>Invoice Number</Form.Label>
										<Form.Control placeholder="Invoice Number" value={inovoiceNumber} onChange={(e) => setInvoiceNumber(e.target.value)} />
									</Form.Group>
								</Col>
								<Col >
									<Form.Group controlId="Invoice Date">
										<Form.Label>Invoice Date</Form.Label>
										<Form.Control placeholder="Invoice Date" value={inovoiceDate} onChange={(e) => setInvoiceDate(e.target.value)} />
									</Form.Group>
								</Col>
								<Col >
									<Form.Group controlId="Due Date">
										<Form.Label>Due Date</Form.Label>
										<Form.Control placeholder="Due Date" value={inovoiceDueDate} onChange={(e) => setInvoiceDueDate(e.target.value)} />
									</Form.Group>
								</Col>
								<Col >
									<Form.Group controlId="shippingPrice">
										<Form.Label>shipping Price</Form.Label>
										<Form.Control placeholder="Enter Shipping Price" value={shippingPrice} onChange={(e) => setShippingPrice(e.target.value)
										} />
									</Form.Group>
								</Col>
								<Col >
									<Form.Group controlId="shippingDetails">
										<Form.Label>shippingDetails</Form.Label>
										<Form.Control placeholder="Enter Shipping Details" value={shippingDetails} onChange={(e) => setShippingDetails(e.target.value)
										} />
									</Form.Group>
								</Col>
								<Col >
									<Form.Group controlId="invoiceTotal">
										<Form.Label>invoice Total</Form.Label>
										<Form.Control placeholder="Enter total Invoice Amount" value={invoiceTotal} onChange={(e) => setInvoiceTotal(e.target.value)
										} />
									</Form.Group>
								</Col>

								<Col >
									<Form.Group controlId="invoicePaid">
										<Form.Label>Invoice Paid ?</Form.Label>
										<Form.Control placeholder="Eenter Yes/No" value={invoicePaid} onChange={(e) => setInvoicePaid(e.target.value)
										} />
									</Form.Group>
								</Col>

								<Col >
									<Form.Group controlId="invoicePaidDate">
										<Form.Label>invoice Paid Date</Form.Label>
										<Form.Control placeholder="Eenter Date DD/MM/YYYY" value={invoicePaidDate} onChange={(e) => setInvoicePaidDate(e.target.value)
										} />
									</Form.Group>
								</Col>

								<Col >
									<Form.Group controlId="invoicePaidAmount">
										<Form.Label>Invoice Paid Amount</Form.Label>
										<Form.Control placeholder="Eenter paid Amount number" value={invoicePaidAmount} onChange={(e) => setInvoicePaidAmount(e.target.value)
										} />
									</Form.Group>
								</Col>
							</Col>
							<Col>
								<h4 style={heading}>GST Details</h4>
								<Col >
									<Form.Group controlId="gstNumber">
										<Form.Label>Gst Number</Form.Label>
										<Form.Control placeholder="Enter 14 Digits Number" value={gstNumber} onChange={(e) =>
											setGstNumber(e.target.value)
										} />
									</Form.Group>
								</Col>

								<Col >
									<Form.Group controlId="gstType">
										<Form.Label>Gst Type</Form.Label>
										<Form.Control placeholder="What is Your HSN" value={gstType} onChange={(e) =>
											setGstType(e.target.value)
										} />
									</Form.Group>
								</Col>

								<Col >
									<Form.Group controlId="gstRate">
										<Form.Label>Gst Rate</Form.Label>
										<Form.Control placeholder="Enter % Number" value={gstRate} onChange={(e) =>
											setGstRate(e.target.value)
										} />
									</Form.Group>
								</Col>

								<Col >
									<Form.Group controlId="gstAmount">
										<Form.Label>Gst Amount</Form.Label>
										<Form.Control placeholder="Enter Your Gst Amount" value={gstAmount} onChange={(e) =>
											setGstAmount(e.target.value)
										} />
									</Form.Group>
								</Col>

								<h4 style={heading} className='mt-4'>Invoice Items</h4>
								<Col >
									<Form.Group controlId="name">
										<Form.Label>name</Form.Label>
										<Form.Control placeholder="Enter Name" value={invoiceName} onChange={(e) =>
											setInvoiceName(e.target.value)
										} />
									</Form.Group>
								</Col>
								<Col >
									<Form.Group controlId="itemQuantity">
										<Form.Label>Item Quantity</Form.Label>
										<Form.Control placeholder="Enter Quantity" value={invoiceQuantity} onChange={(e) =>
											setInvoiceQuantity(e.target.value)
										} />
									</Form.Group>
								</Col>

								<Col >
									<Form.Group controlId="itemPrice">
										<Form.Label>Item Price</Form.Label>
										<Form.Control placeholder="Enter Price" value={invoicePrice} onChange={(e) =>
											setInvoicePrice(e.target.value)
										} />
									</Form.Group>
								</Col>

								<Col >
									<Form.Group controlId="itemTotal">
										<Form.Label>Item Total</Form.Label>
										<Form.Control placeholder="Enter Total Price" value={invoiceItemTotal} onChange={(e) =>
											setInvoiceItemTotal(e.target.value)
										} />
									</Form.Group>
								</Col>
							</Col>
						</Row>

						<Row className="mb-1">
							<Col>
								<h4 style={heading}>Vendor Details</h4>

								<Col >
									<Form.Group controlId="name">
										<Form.Label>name</Form.Label>
										<Form.Control placeholder="Enter Name" value={vendorName} onChange={(e) =>
											setVendorName(e.target.value)} />
									</Form.Group>
								</Col>
								<Col >
									<Form.Group controlId="phone">
										<Form.Label>phone</Form.Label>
										<Form.Control placeholder=" Enter phone Number" value={vendorPhone} onChange={(e) =>
											setVendorPhone(e.target.value)
										} />
									</Form.Group>
								</Col>

								<Col >
									<Form.Group controlId="address">
										<Form.Label>Address</Form.Label>
										<Form.Control placeholder="Enter address" value={vendorAddress} onChange={(e) =>
											setVendorAddress(e.target.value)
										} />
									</Form.Group>
								</Col>
								<Col >
									<Form.Group controlId="vendorContactNote">
										<Form.Label>vendor Note</Form.Label>
										<Form.Control placeholder="Write a Note" value={vendorContactNote} onChange={(e) =>
											setVendorContactNote(e.target.value)
										} />
									</Form.Group>
								</Col>

								<Col >
									<Form.Group controlId="city">
										<Form.Label>City</Form.Label>
										<Form.Control placeholder="Enter City" value={vendorCity} onChange={(e) =>
											setVendorCity(e.target.value)} />
									</Form.Group>
								</Col>
								<Col >
									<Form.Group controlId="pinCode">
										<Form.Label>Pin Code</Form.Label>
										<Form.Control placeholder="Enter Pin Code" value={vendorPinCode} onChange={(e) =>
											setVendorPinCode(e.target.value)
										} />
									</Form.Group>
								</Col>
								<Col >
									<Form.Group controlId="country">
										<Form.Label>Country</Form.Label>
										<Form.Control placeholder="Enter Country" value={vendorCountry} onChange={(e) =>
											setVendorCountry(e.target.value)
										} />
									</Form.Group>
								</Col>
								<Col >
									<Form.Group controlId="email">
										<Form.Label>Email</Form.Label>
										<Form.Control placeholder="Enter Email Id" value={vendorEmail} onChange={(e) =>
											setVendorEmail(e.target.value)
										} />
									</Form.Group>
								</Col>
							</Col>
							<Col>
								<h4 style={heading}>Payments Details</h4>
								<Col >
									<Form.Group controlId="paymentId">
										<Form.Label>payment Id</Form.Label>
										<Form.Control placeholder="Enter payment ID" value={paymentId} onChange={(e) =>
											setPaymentId(e.target.value)
										} />
									</Form.Group>
								</Col>
								<Col >
									<Form.Group controlId="paymentMethod">
										<Form.Label>payment Method</Form.Label>
										<Form.Control placeholder="payment mode" value={paymentMethod} onChange={(e) =>
											setPaymentMethod(e.target.value)
										} />
									</Form.Group>
								</Col>

								<Col >
									<Form.Group controlId="amount">
										<Form.Label>Amount</Form.Label>
										<Form.Control placeholder="Enter payment Amount" value={paymentAmount} onChange={(e) =>
											setPaymentAmount(e.target.value)
										} />
									</Form.Group>
								</Col>

								<Col >
									<Form.Group controlId="status">
										<Form.Label>Status</Form.Label>
										<Form.Control placeholder="Enter Total Price" value={paymentStatus} onChange={(e) =>
											setPaymentStatus(e.target.value)
										} />
									</Form.Group>
								</Col>
								<Col >
									<Form.Group controlId="updateTime">
										<Form.Label>update Time</Form.Label>
										<Form.Control placeholder="Enter eg: 03:24" value={paymentTime} onChange={(e) =>
											setPaymentTime(e.target.value)
										} />
									</Form.Group>
								</Col>
								<Col >
									<Form.Group controlId="email">
										<Form.Label>Enter Email</Form.Label>
										<Form.Control placeholder="Enter Your Email" value={paymentEmail} onChange={(e) =>
											setPaymentEmail(e.target.value)
										} />
									</Form.Group>
								</Col>
							</Col>

						</Row>
						<Button variant="primary" type="submit">
							Submit
						</Button>
					</Form>
				</>)}


		</div >
	)
}

export default InvoiceCreateScreen


const heading = {
	textAlign: 'center',
	color: '#000'
}