import { React } from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";

const ProductCard = ({ product }) => {
  return (
    <Card className=" p-1 rounded zoom bundle-card-size">
      <Link to={`/product/${product._id}`}>
        <Card.Img
          className="bundle-card-img"
          src={product.images[0] ? product.images[0] : "./img/sample.jpg"}
          alt={window.location.origin + "./img/sample.jpg"}
          variant="top"
        />
      </Link>
      <Card.Body className="card-size-body">
        <Link to={`/product/${product._id}`}>
          <Card.Title>
            <div style={{ margin: "0" }} className="bundle-card-name">
              {" "}
              {product.name}
            </div>
            <div style={{ marginTop: "0.1rem", height: '0.8rem' }} className="bundle-card-name-size">
              {product.size} </div>
          </Card.Title>
        </Link>
        <Card.Text className="card-text-inline" style={{ margin: "0" }}>
          <Card.Text style={{ marginTop: '-0.8rem' }}>
            <span className="bundle-card-text-price">₹{product.price} </span>

            {product.mrp > product.price && (
              <span className="bundle-card-text-mrp">₹{product.mrp}</span>
            )}

            {parseInt(
              (((product.mrp - product.price) / product.mrp) * 100).toFixed(0)
            ) === 0 ? (
              " "
            ) : (
              <strong className="card-text-off">
                -{" "}
                {(((product.mrp - product.price) / product.mrp) * 100).toFixed(
                  0
                )}
                %
              </strong>
            )}
          </Card.Text>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default ProductCard;
