
import { React, useEffect } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { Col } from 'react-bootstrap'
import Product from '../Product'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../Message'
import { listTopProducts } from '../../actions/productActions'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 425 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 425, min: 0 },
    items: 3,
  },
}

const TopRated = () => {
  const dispatch = useDispatch()

  const productTopRated = useSelector((state) => state.productTopRated)
  const { error, products } = productTopRated

  useEffect(() => {
    dispatch(listTopProducts())
  }, [dispatch])

  return error ? (
    <Message variant='warning'>{error}</Message>
  ) : (
    <div >
      <h1 className='carouselHeading'>  TOP RATED </h1>
      <Carousel
        autoPlay
        autoPlaySpeed={8000}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        keyBoardControl={true}
        customTransition='all .5'
        transitionDuration={500}
        removeArrowOnDeviceType={['tablet', 'mobile']}
        dotListClass='custom-dot-list-style'
        itemClass='carousel-item-padding-40-px'
        minimumTouchDrag={80}
        swipeable={true}
        draggable={true}

      >
        {products.map((product, index) => index < 8 && (

          <Col key={product._id} xs={12} sm={12} md={12} lg={12} xl={12}>
            <Product product={product} id='product' />
          </Col>

        ))}

      </Carousel>
    </div>
  )
}

export default TopRated


