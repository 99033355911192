import React, { useEffect, useState } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Button, Row, Col, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PaginateOrders from "../components/PaginateOrders";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { listOrders } from "../actions/orderActions";

const OrderListScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1;
  const orderStatus = match.params.orderStatus || "new";
  const dispatch = useDispatch();
  const [key, setKey] = useState(orderStatus);
  const orderList = useSelector((state) => state.orderList);
  const { loading, error, orders, pages, page } = orderList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo && userInfo.role.includes("admin")) {
      switch (key) {
        case "new":
          dispatch(listOrders(pageNumber, false, false));
          break;
        case "paid":
          dispatch(listOrders(pageNumber, true, false));
          break;
        case "delivered":
          dispatch(listOrders(pageNumber, true, true));
          break;
        case "all":
          dispatch(listOrders(pageNumber));
          break;
        default:
          dispatch(listOrders(false, false, pageNumber));
          break;
      }
    } else {
      history.push("/login");
    }
  }, [dispatch, history, userInfo, key, pageNumber]);

  return (
    <>
      <h1>Order Details </h1>
      <Row
        style={{
          textAlign: "center",
          verticalAlign: " middle",
          lineHeight: "20px",
        }}
      >
        <Col lg={5} md={6} xs={12}>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey="new" title="New"></Tab>
            <Tab eventKey="paid" title="Paid"></Tab>
            <Tab eventKey="delivered" title="Delivered"></Tab>
            <Tab eventKey="all" title="All"></Tab>
          </Tabs>
        </Col>
      </Row>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="warning">{error}</Message>
      ) : (
        <>
          <>
            <Row className="justify-content-center mb-3 mt-3 tableHead">
              <Col>Photo</Col>
              <Col>User</Col>
              <Col>Date</Col>
              <Col>Total</Col>
              <Col>Paid</Col>
              <Col>Delivered</Col>
              <Col>Action</Col>
            </Row>
            {orders.map((order) => (
              <Row className="justify-content-center tableData">
                <Col>
                  <img
                    src={order.orderItems[0].image}
                    alt={order.orderItems[0].name}
                    width="100"
                  />
                </Col>
                <Col>{order.user ? order.user.name : "user"} </Col>
                <Col>{order.createdAt.substring(0, 10)} </Col>
                <Col>₹ {order.totalPrice} </Col>
                <Col>
                  {order.isPaid ? (
                    <i className="fas fa-check" style={{ color: "green" }}></i>
                  ) : (
                    <i className="fas fa-times" style={{ color: "red" }}></i>
                  )}{" "}
                </Col>
                <Col>
                  {" "}
                  {order.isDelivered ? (
                    <i className="fas fa-check" style={{ color: "green" }}></i>
                  ) : (
                    <i className="fas fa-times" style={{ color: "red" }}></i>
                  )}
                </Col>
                <Col>
                  <LinkContainer to={`/order/${order._id}`}>
                    <Button variant="primary">Details</Button>
                  </LinkContainer>
                </Col>
              </Row>
            ))}
          </>
          <PaginateOrders pages={pages} orderStatus={key} page={page} />
        </>
      )}
    </>
  );
};

export default OrderListScreen;
