import {
  STORE_CONFIG_REQUEST,
  STORE_CONFIG_SUCCESS,
  STORE_CONFIG_FAIL,
} from "../constants/storeConstants";

export const getStoreConfigReducer = (state = { store: {} }, action) => {
  switch (action.type) {
    case STORE_CONFIG_REQUEST:
      return { loading: true, store: {} };
    case STORE_CONFIG_SUCCESS:
      return { loading: false, store: action.payload };
    case STORE_CONFIG_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
