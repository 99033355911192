export const ELECTRONICS = 'Electronics'
export const CLOTHING = 'Clothing'
export const FOOD = 'Food'
export const BOOKS = 'Books'
export const TOYS = 'Toys'
export const SPORTS = 'Sports'
export const FURNITURE = 'Furniture'
export const GROCERY = 'Grocery'
export const FOOTWEAR = 'Footwear'
export const TEST = 'Test'
export const OTHER = 'Other'