import React from 'react'
import axios from 'axios'
import { useCSVDownloader } from 'react-papaparse'
import { useSelector } from 'react-redux'

const AllProductsData = () => {

	const [AllProductsData, setAllProductsData] = React.useState([])

	const { CSVDownloader, Type } = useCSVDownloader();

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin




	React.useEffect(() => {

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}
		axios.get('/api/store/undefined/products/allproducts', config).then((res) => {
			const data = res.data.products
			const productData = data.map((product) => {
				return {
					name: product.name,
					price: product.price,
					mrp: product.mrp,
					images: product.images,
					sku: product.sku,
					brand: product.brand,
					status: product.status,
					category: product.category,
					subCategory: product.subCategory,
					countInStock: product.countInStock,
					description: product.description,
				}
			})
			setAllProductsData(productData)
		}).catch((err) => {
			console.log(err);

		})

	}, [userInfo.token])






	return (
		<div>{console.log(AllProductsData)}

			<CSVDownloader
				type={Type.Button}
				filename={'filename'}
				bom={true}
				className='btn btn-dark float-end'
				data={AllProductsData}
			> Download CSV File
			</CSVDownloader>
		</div>
	)
}

export default AllProductsData