import React, { useEffect } from 'react'
import { Row, Col, ListGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getOrderDetails} from '../actions/orderActions'

const OrderScreen = ({ match }) => {
  const orderId = match.params.id

  const dispatch = useDispatch()

  const orderDetails = useSelector((state) => state.orderDetails)
  const { order, loading, error } = orderDetails



  if (!loading) {
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2)
    }
    order.itemsPrice = addDecimals(order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0))
  }

  useEffect(() => {
    dispatch(getOrderDetails(orderId))
  }, [dispatch, orderId])

  

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant='light'>{error}</Message>
  ) : (
    <>
      <Row>
        <Col md={6}>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h4 className='con'>
                <b>Mufeed Products And Services</b>
              </h4>
              <h4 className='con'>beside SBI Bank Chitguppa,</h4>
              <h4 className='con mb-5'>585412</h4>

              {order.orderItems.length === 0 ? (
                <Message>No Orders</Message>
              ) : (
                <ListGroup variant='flush'>
                  <h5>
                    Customer Number : {order.phone} <h5 style={{ float: 'right' }}>Date :{order.createdAt} </h5>
                  </h5>

                  <h5 className='mb-5' id='orderId'>
                    Order Id:{order._id}
                  </h5>
                  <table>
                    {/* //striped bordered hover resource className='table-sm' */}

                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>QTY</th>
                        <th></th>
                        <th>Price</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order.orderItems.map((item, index) => (
                        <tr style={{ borderBottom: '1px solid ' }} key={item.id}>
                          <td>{item.name}</td>
                          <td>{item.qty}</td>
                          <td>X</td>
                          <td>₹ {item.price}</td>
                          <td>₹ {item.qty * item.price}</td>
                        </tr>
                      ))}
                      <tr>
                        <td>Total Amount:</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>₹ {order.totalPrice}</td>
                      </tr>
                    </tbody>
                    {/* <thead>
                      <tr>
                        <th>NO.</th>
                        <th>Description</th>
                        <th>Qty</th>
                        <th>Price</th>
                        <th>TOTAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order.orderItems.map((item, index) => (
                        <tr key={item._id}>
                          <td sequenceNumber={index}>{item}</td>
                          <td>{item.name}</td>
                          <td> {item.qty} </td>
                          <td>X ₹{item.price} </td>
                          <td>= ₹ {item.qty * item.price}</td>
                        </tr>
                      ))}
                    </tbody> */}
                  </table>
                  <br />
                  <div>
                    <p className='con'>ThankYou For Shopping</p>

                    <p className='con'>Visit Again!</p>
                  </div>

                  {/*  {order.orderItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col>
                          <p>{item.name}</p>
                        </Col>
                        <Col>
                          {item.qty} X ₹{item.price} = ₹ {item.qty * item.price}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))} */}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>

          {/* <Col>
            <Card>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <p>Order Summary</p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Items :</Col>
                    <Col>₹ {order.itemsPrice}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Total Amount:</Col>
                    <Col>₹ {order.totalPrice}</Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col> */}
        </Col>
      </Row>
    </>
  )
}

export default OrderScreen