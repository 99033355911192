import axios from "axios";
import env from "dotenv";
import {
  STORE_CONFIG_REQUEST,
  STORE_CONFIG_SUCCESS,
  STORE_CONFIG_FAIL,
} from "../constants/storeConstants";
env.config();
axios.defaults.baseURL = process.env.REACT_APP_API_SERVER;
const storeId = process.env.REACT_APP_STORE_ID;

export const getStoreConfig = () => async (dispatch, getState) => {
  try {
    dispatch({ type: STORE_CONFIG_REQUEST });
    const { data } = await axios.get(`/api/config/${storeId}`);

    dispatch({
      type: STORE_CONFIG_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STORE_CONFIG_FAIL,
      payload: error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};
