import React from 'react'
import { Button } from 'react-bootstrap'

const SubCategory = ({ history, subCategory }) => {
	const submitHandler = (e) => {
		e.preventDefault()
		if (subCategory.name.trim()) {
			history.push(`/subcategory/${subCategory.name}`)
		} else {
			history.push('/')
		}
	}

	console.log(subCategory);

	return (
		<>
			<div onClick={submitHandler} className='zoom mb-2'  >
				{/* <div className='sub-category-img '>
					<img src={window.location.origin + subCategory.image} style={{ width: '100%', height: '100%' }} alt='img' variant='top' />
				</div> */}

				<div className='subCategory-btn' >
					<Button className='mx-auto'>
						{subCategory.name}
					</Button>
				</div>
			</div>
		</>

	)
}

export default SubCategory


