import React, { useEffect, useState } from 'react'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button } from 'react-bootstrap'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import firebase from './Firebase'
import { Link } from 'react-router-dom'
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import { register } from '../actions/userActions'

const LoginPhone = ({ location, history }) => {
  const [number, setNumber] = useState('')
  const [otp, setOtp] = useState('')
  const [flag, setFlag] = useState(false)
  const [confirmObj, setConfirmObj] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, userInfo } = userLogin
  const redirect = location.search ? location.search.split('=')[1] : '/'
  const dispatch = useDispatch()

  console.log(firebase.name)

  useEffect(() => {
    if (userInfo) {
      history.push(redirect)
    }
  }, [history, userInfo, redirect])

  const getOtp = async (e) => {
    e.preventDefault()
    setErrorMessage('')
    if (number === '' || number === undefined) return setErrorMessage(' please enter a valid phone number!')
    try {
      const response = await setUpRecaptcha(number)
      console.log(response)
      setConfirmObj(response)
      setFlag(true)
    } catch (err) {
      if (err.message.includes('TOO_SHORT')) {
        setErrorMessage('Number Should be 10 Digits! Its Less than 10')
      } else if (err.message.includes('TOO_LONG')) {
        setErrorMessage('Number Should be 10 Digits! Its More than 10')
      } else {
        setErrorMessage(err.message)
      }
    }
    console.log(number)
  }

  const setUpRecaptcha = (number) => {
    const auth = getAuth()
    const recaptchaVerifier = new RecaptchaVerifier(
      'sign-in-button',
      {
        size: 'invisible',
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log('reCAPTCHA solved, allow signInWithPhoneNumber.')
        },
      },
      auth
    )
    return signInWithPhoneNumber(auth, number, recaptchaVerifier)
  }

  const verifyOtp = async (e) => {
    e.preventDefault()
    console.log(otp)
    if (otp === '' || otp === null) return
    try {
      setErrorMessage('')
      const { user } = await confirmObj.confirm(otp)
      console.log(user)
      dispatch(register(`${number}`, `${number}`, `${number}@retailcenter.in`, user.uid))
    } catch (err) {
      if (err.message.includes('INVALID_CODE')) {
        setErrorMessage('Please Enter Valid OTP')
      } else {
        setErrorMessage(err.message)
      }
    }
  }

  return (
    <div className='login-Screen' >
      <p>By Proceeding, You are Agreeing To RetailCenter. <Link to='/termsandconditions'>Terms & Conditions</Link>
      </p>
      <div className='phoneNumber'>
        {errorMessage && <Message variant='warning'>{errorMessage}</Message>}
        {loading && <Loader />}
        <Form className='form1' onSubmit={getOtp} style={{ display: !flag ? 'block' : 'none' }}>
          <h6 >Enter Phone Number</h6>
          <div>
            <PhoneInput style={{ height: '3rem' }} defaultCountry='IN' value={number} onChange={setNumber} placeholder='10 Digit Mobile Number' />
            <Form.Text className="text-muted">
              We'll never share your Phone Number with anyone else.
            </Form.Text>
          </div>
          <div className='move-right mt-2 '>
            <Button id='sign-in-button' type='submit' variant='secondary'    >
              Send OTP
            </Button>
          </div>
          <div id='recaptcha-container' />
        </Form>

        <Form className='form2' onSubmit={verifyOtp} style={{ display: flag ? 'block' : 'none' }}>
          <h2 style={{ color: 'black' }}>Enter Your OTP</h2>
          <Form.Group className='mb-2' controlId='formBasicOtp'>
            <Form.Control
              style={{ border: '2px solid #2aa198', borderRadius: '4px' }}
              type='text'
              placeholder='Enter 6 Digits Number'
              onChange={(e) => {
                setOtp(e.target.value)
              }}
            />
          </Form.Group>
          <div className='move-right mt-3'>
            <Button type='submit' variant='success'>
              Verify OTP
            </Button>
            <Link to='/'>
              <Button style={{ marginLeft: '0.8rem' }} type='submit' variant='info'>
                Cancel
              </Button>
            </Link>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default LoginPhone
