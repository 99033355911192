import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listProductDetails, updateProduct } from '../actions/productActions'
import { PRODUCT_UPDATE_RESET } from '../constants/productConstants'
import Resizer from 'react-image-file-resizer'
import categories from '../components/categories'

const ProductEditScreen = ({ match, history }) => {
  const productId = match.params.id

  const [name, setName] = useState('')
  const [size, setSize] = useState('')
  const [price, setPrice] = useState(0)
  const [mrp, setMrp] = useState(0)
  const [images, setImages] = useState([])
  const [sku, setSku] = useState('')
  const [brand, setBrand] = useState('')
  const [status, setStatus] = useState('')
  const [description, setDescription] = useState('')
  const [countInStock, setCountInStock] = useState(0)
  const [category, setCategory] = useState(' ')
  const [subCategory, setSubCategory] = useState('')
  const [uploading, setUploading] = useState(false)


  const dispatch = useDispatch()

  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product } = productDetails

  const productUpdate = useSelector((state) => state.productUpdate)
  const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = productUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET })
      history.push('/admin/productlist')
    } else {
      if (!product.name || product._id !== productId) {
        dispatch(listProductDetails(productId))
      } else {
        setName(product.name)
        setSize(product.size)
        setPrice(product.price)
        setMrp(product.mrp)
        setImages(product.images)
        setSku(product.sku)
        setBrand(product.brand)
        setStatus(product.status)
        setDescription(product.description)
        setCategory(product.category)
        setSubCategory(product.subCategory)
        setCountInStock(product.countInStock)
      }
    }
  }, [dispatch, productId, product, history, successUpdate])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateProduct({
        _id: productId,
        name,
        size,
        price,
        mrp,
        images,
        sku,
        brand,
        status,
        category,
        subCategory,
        description,
        countInStock,
      })
    )
  }

  const uploadFileHandler = async (e) => {
    const formData = new FormData()
    const files = e.target.files

    if (files) {
      setUploading(true)
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        Resizer.imageFileResizer(
          file,
          750,
          750,
          'JPEG',
          400,
          0,
          (blob) => {
            formData.append('images', blob, file.name)
            axios
              .post('/api/upload', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
              .then((response) => {
                setUploading(false)
                const updateResponse = response.data.split(',')
                setImages(updateResponse)
              })
              .catch((err) => {
                console.log(err)
                setUploading(false)
              })
          },
          'blob'
        )
      }
    }
  }

  return (
    <>
      <Link to='/admin/productlist' className='btn btn-secondary my-3'>
        {' '}
        Go Back
      </Link>
      <div className='mb-4'>
        <h3>Edit Product</h3> {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='warning'>{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='warning'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Row>
              <Col xs={12} sm={6}>
                <Form.Group controlId='name'>
                  <label className='mt-2'>Name </label>
                  <Form.Control type='name' placeholder='Enter Product Name' value={name} onChange={(e) => setName(e.target.value)}></Form.Control>
                </Form.Group>
                <Form.Group controlId='size'>
                  <label className='mt-2'>Size or Weight</label>
                  <Form.Control type='name' placeholder='Enter number in g, kg /ml, L' value={size} onChange={(e) => setSize(e.target.value)}></Form.Control>
                </Form.Group>
                <Form.Group controlId='price'>
                  <label className='mt-2'>Price</label>
                  <Form.Control type='number' placeholder='Enter Price' value={price} onChange={(e) => setPrice(e.target.value)}></Form.Control>
                </Form.Group>
                <Form.Group controlId='mrp'>
                  <label className='mt-2'>MRP</label>
                  <Form.Control type='number' placeholder='Enter Price' value={mrp} onChange={(e) => setMrp(e.target.value)}></Form.Control>
                </Form.Group>
                <Form.Group controlId='countInStock'>
                  <label className='mt-2'>Count In Stock</label>
                  <Form.Control type='number' placeholder='Enter Count In Stock' value={countInStock} onChange={(e) => setCountInStock(e.target.value)}></Form.Control>
                </Form.Group>
                <Form.Group controlId='images'>
                  <label className='mt-2'>Images</label>
                  <Form.Control type='text' placeholder='Enter Image URL ' value={images} onChange={(e) => setImages(e.target.value)}></Form.Control>
                  <label className='btn btn-secondary'>
                    Choose File
                    <Form.File id='image-file' multiple hidden custom onChange={uploadFileHandler}></Form.File>
                  </label>
                  {uploading && <Loader />}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId='brand'>
                  <label className='mt-2'>Brand</label>
                  <Form.Control type='text' placeholder='Enter Brand Name' value={brand} onChange={(e) => setBrand(e.target.value)}></Form.Control>
                </Form.Group>
                <Form.Group controlId='status'>
                  <label className='mt-2'>Status</label>
                  <select className='form-control' type='form-select' placeholder='Enter Status' value={status} onChange={(e) => setStatus(e.target.value)}>
                    <option value='In Review'> In Review</option>
                    <option value='Published'>Published</option>
                  </select>
                </Form.Group>

                <Form.Group controlId="category">
                  <Form.Label>Category</Form.Label>
                  <Form.Control
                    type='text' placeholder='Enter Category ' value={category} onChange={(e) => setCategory(e.target.value)}
                    as="select"
                  >
                    {categories.map((allCategory) => (

                      <option key={allCategory.id} value={allCategory.name}>{allCategory.name}</option>
                    ))}

                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="subCategory">
                  <Form.Label>Sub Category</Form.Label>
                  <Form.Control
                    type='text' placeholder='Enter Sub Category ' value={subCategory} onChange={(e) => setSubCategory(e.target.value)}
                    as="select"

                  >

                    {

                      categories.filter((subCategory) => (subCategory.name === category)).map((category) => {
                        console.log(category.name);
                        console.log(category.subCategories)
                        return (

                          category.subCategories.map((subCategory) => (
                            < option key={subCategory.id} value={subCategory.name} > {subCategory.name}</option>
                          ))
                        )
                      })}


                  </Form.Control>

                </Form.Group>
                <Form.Group controlId='description'>
                  <label className='mt-2'>Description</label>
                  <Form.Control type='text' placeholder='Enter Description ' value={description} onChange={(e) => setDescription(e.target.value)}></Form.Control>
                </Form.Group>
                <Form.Group controlId='sku'>
                  <label className='mt-2'>SKU</label>
                  <Form.Control type='text' placeholder='Enter SKU' value={sku} onChange={(e) => setSku(e.target.value)}></Form.Control>
                </Form.Group>
                <Form.Group className='d-grid gap-2 mt-2 mb-5'>
                  <Button variant='secondary' size='lg' type='submit'>
                    Update
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        )}
      </div>
    </>
  )
}

export default ProductEditScreen
