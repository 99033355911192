import React from 'react'

import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { Button, Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { useEffect } from 'react'
import axios from 'axios'
import { useState } from 'react'

const OrderInvoiceScreen = () => {
	const [invoices, setInvoices] = useState('')
	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const orderList = useSelector((state) => state.orderList)
	const { loading, error/* , orders, pages, page  */ } = orderList


	useEffect(() => {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}
		axios
			.get(`/api/invoice/myinvoices`, config)
			.then((res) => {
				console.log(res);
				setInvoices(res.data)

			})
			.catch((err) => {
				console.log(err)
			})
	}, [userInfo.token])

	const confi = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${userInfo.token}`,
		},
	}

	const deleteInvoiceHandler = async (invoiceId) => {
		await axios.delete(`/api/invoice/${invoiceId}`, confi)

	}



	console.log(invoices);

	return (
		<div>
			{loading ? (
				<Loader />
			) : error ? (
				<Message variant='warning'>{error}</Message>
			) : (
				<>
					<Button variant='secondary'>
						<Link to='/admin/invoice/create' style={{ color: "white" }}>create invoice</Link>
					</Button>
					<Table striped bordered hover size="sm" style={{ textAlign: "center" }} className="mt-3">
						<thead>
							<tr>
								<th>#</th>
								<th>Vendor Name</th>
								<th>Invoice Date</th>
								<th>Amount</th>
								<th>Due</th>
								<th>Due Date</th>
								<th>Edit</th>
								<th>Delete</th>
							</tr>
						</thead>
						{invoices && (
							<tbody>
								{invoices.map((invoice, index) => (
									<tr>
										<td>{index}</td>
										<td>{invoice.vendorDetails.name}</td>
										<td>{(invoice.invoiceDate).slice(0, 10)}</td>
										<td>{invoice.invoiceTotal}</td>
										<td>{(invoice.invoiceTotal) - (invoice.invoicePaidAmount)}</td>
										<td>{(invoice.invoiceDueDate).slice(0, 10)}</td>
										<td>
											<LinkContainer to={`/admin/invoice/${invoice._id}/edit`}>
												<Button variant='light' className='btn-sm'>
													<i className='fas fa-edit'></i>
												</Button>
											</LinkContainer>
										</td>
										<td>
											<Button variant='light' style={{ color: 'red' }} onClick={() => deleteInvoiceHandler(invoice._id)} className='btn-sm' >
												<i className='fas fa-trash'></i>
											</Button>
										</td>
									</tr>
								))}
							</tbody>
						)}
					</Table>


				</>)
			}
		</div >
	)
}

export default OrderInvoiceScreen
