import axios from 'axios'
import env from 'dotenv'
import { CART_CLEAR_ITEMS } from '../constants/cartConstants'
import { USER_DETAILS_RESET } from '../constants/userConstants'
import {
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_REQUEST,
  ORDER_PAY_FAIL,
  ORDER_PAY_SUCCESS,
  ORDER_PAY_REQUEST,
  ORDER_LIST_MY_REQUEST,
  ORDER_LIST_MY_SUCCESS,
  ORDER_LIST_MY_FAIL,
  ORDER_LIST_FAIL,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_REQUEST,
  ORDER_DELIVER_FAIL,
  ORDER_DELIVER_SUCCESS,
  ORDER_DELIVER_REQUEST,
  ORDER_CREATE_RESET,
  ORDER_TOP_SELLING_REQUEST,
  ORDER_TOP_SELLING_SUCCESS,
  ORDER_TOP_SELLING_FAIL,
  ORDER_SALE_REPORT_REQUEST,
  ORDER_SALE_REPORT_SUCCESS,
  ORDER_SALE_REPORT_FAIL,
} from '../constants/orderConstants'
import { logout } from './userActions'

env.config()
const printUrl = process.env.REACT_APP_PRINT_SERVER
const storeId = process.env.REACT_APP_STORE_ID
console.log(printUrl)

export const createOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/orders/shipments`, order, config)

    dispatch({
      type: ORDER_CREATE_SUCCESS,
      payload: data,
    })

    dispatch({
      type: CART_CLEAR_ITEMS,
      payload: data,
    })
    localStorage.removeItem('cartItems')
  } catch (error) {
    const message = error.response && error.response.data.message ? error.response.data.message : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: ORDER_CREATE_FAIL,
      payload: message,
    })
  }
}

export const createOrderAdmin = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/orders`, order, config)

    dispatch({
      type: ORDER_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message = error.response && error.response.data.message ? error.response.data.message : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: ORDER_CREATE_FAIL,
      payload: message,
    })
  }
}

export const printOrder = (order) => async (dispatch) => {
  try {
    const { res } = await axios.post(`${printUrl}/print`, order)
    console.log(res)
    if (res.status === 200) {
      dispatch({ type: USER_DETAILS_RESET })
      dispatch({ type: ORDER_CREATE_RESET })
    }
  } catch (error) {
    const message = error.response && error.response.data.message ? error.response.data.message : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
  }
}

export const SendOrdertoWhatsApp = (order, userInfo) => async (dispatch) => {
  try {
    console.log(order)
    const message = `Hi ${userInfo.name},\n\nThank you for shopping with us.\n\nYour order has been placed successfully.\n\nOrder Id: ${order._id}\n\nOrder Items:\n${order.orderItems
      .map((item) => `${item.name} - ${item.qty} x ${item.price} = ${item.price * item.qty}`)
      .join('\n')}\n\nTotal: ${order.itemsPrice}\n\nDelivery Address:\n${order.address}\n\nDelivery Date: ${order.deliveryDate}\n\nDelivery Time: ${order.deliveryTime}\
    \n\nThank you for shopping with us.\n\nRegards,\n\nTeam ${userInfo.name}`
    const url = `https://api.whatsapp.com/send?phone=${order.shippingAddress.phone}&text=${message}`
    window.open(url, '_blank')
  } catch (error) {
    const message = error.response && error.response.data.message ? error.response.data.message : error.message
    console.log(message)
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
  }
}

export const getOrderDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DETAILS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/orders/${id}`, config)

    dispatch({
      type: ORDER_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message = error.response && error.response.data.message ? error.response.data.message : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload: message,
    })
  }
}

export const payOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_PAY_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.put(`/api/orders/${order._id}/pay`, {}, config)
    dispatch({
      type: ORDER_PAY_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ORDER_PAY_FAIL,
      payload: error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const deliverOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DELIVER_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/api/orders/${order._id}/deliver`, {}, config)

    dispatch({
      type: ORDER_DELIVER_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message = error.response && error.response.data.message ? error.response.data.message : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: ORDER_DELIVER_FAIL,
      payload: message,
    })
  }
}

export const listMyOrders = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_LIST_MY_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/orders/myorders?store=${storeId}`, config)

    dispatch({
      type: ORDER_LIST_MY_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message = error.response && error.response.data.message ? error.response.data.message : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: ORDER_LIST_MY_FAIL,
      payload: message,
    })
  }
}

export const listOrders =
  (pageNumber = '', isPaid, isDelivered) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: ORDER_LIST_REQUEST,
        })

        const {
          userLogin: { userInfo },
        } = getState()

        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }

        if (isPaid !== undefined) {
          const { data } = await axios.get(`/api/orders?pageNumber=${pageNumber}&isPaid=${isPaid}&isDelivered=${isDelivered}`, config)
          dispatch({
            type: ORDER_LIST_SUCCESS,
            payload: data,
          })
        } else {
          const { data } = await axios.get(`/api/orders?pageNumber=${pageNumber}`, config)
          dispatch({
            type: ORDER_LIST_SUCCESS,
            payload: data,
          })
        }
      } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        if (message === 'Not authorized, token failed') {
          dispatch(logout())
        }
        dispatch({
          type: ORDER_LIST_FAIL,
          payload: message,
        })
      }
    }

export const listAllOrders = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_LIST_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/orders/all`, config)
    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message = error.response && error.response.data.message ? error.response.data.message : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: ORDER_LIST_FAIL,
      payload: message,
    })
  }
}
export const topSelling = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_TOP_SELLING_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/orders/topsellingadmin`, config)

    dispatch({
      type: ORDER_TOP_SELLING_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message = error.response && error.response.data.message ? error.response.data.message : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: ORDER_TOP_SELLING_FAIL,
      payload: message,
    })
  }
}

export const saleReport = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_SALE_REPORT_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/orders/report`, config)

    dispatch({
      type: ORDER_SALE_REPORT_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message = error.response && error.response.data.message ? error.response.data.message : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: ORDER_SALE_REPORT_FAIL,
      payload: message,
    })
  }
}

export const dailyReport = (groupBy) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_SALE_REPORT_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/orders/report?groupBy=${groupBy}`, config)

    dispatch({
      type: ORDER_SALE_REPORT_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message = error.response && error.response.data.message ? error.response.data.message : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: ORDER_SALE_REPORT_FAIL,
      payload: message,
    })
  }
}
