import React, { useState } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { FaSearch } from "react-icons/fa";

const SearchBox = ({ history }) => {
  const [keyword, setKeyword] = useState('')

  const submitHandler = (e) => {
    e.preventDefault()
    if (keyword.trim()) {
      history.push(`/search/${keyword}`)
    } else {
      history.push('/')
    }
  }

  return (
    <Form inline>
      <Row>
        <Col xs={10} md={11} >
          <Form.Control type='text' name='q' id='searchBox' onChange={(e) => setKeyword(e.target.value)} placeholder='Search Products' className='mr-sm-2 ml-sm-5'></Form.Control>
        </Col>
        {(window.innerWidth) < 450 ? (
          <Col style={{ position: 'relative', height: '2.4rem' }} xs={1} md={1} >
            <FaSearch className='centerSearch' onClick={submitHandler} style={{ color: 'white', fontSize: '20px' }} />
          </Col>) : (
          <Col xs={1} md={1} >
            <button onClick={submitHandler} className='white_button' id='search'>
              Search
            </button>
          </Col>
        )}
      </Row>
    </Form>
  )
}

export default SearchBox
