import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Container } from "react-bootstrap";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Homescreen from "./screens/Homescreen";
import ProductScreen from "./screens/ProductScreen";
import CartScreen from "./screens/CartScreen";
import LoginScreen from "./screens/LoginScreen";
import LoginEmail from "./screens/LoginEmail";
import RegisterScreen from "./screens/RegisterScreen";
import ProfileScreen from "./screens/ProfileScreen";
import ShippingScreen from "./screens/ShippingScreen";
import PaymentScreen from "./screens/paymentScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
import MyOrderScreen from "./screens/MyOrderScreen";
import UserListScreen from "./screens/UsesrListScreen";
import UserEditScreen from "./screens/UserEditScreen";
import ProductListScreen from "./screens/ProductListScreen";
import ProductEditScreen from "./screens/ProductEditScreen";
import OrderListScreen from "./screens/OrderListScreen";
import BundleScreen from "./screens/BundleScreen";
import ScrollToTop from "./components/ScrollToTop";
import LoginPhone from "./screens/LoginEmail";
import CartScreenAdmin from "./screens/CartScreenAdmin";
import BillingOrders from "./screens/BillingOrders";
import DashBoard from "./screens/DashBoard";
import { PrivacyPolicy } from "./components/PrivacyPolicy";
import RetailHomeScreen from "./components/retailCenter/RetailHomeScreen";
import RefundPolicy from "./components/RefundPolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import ShippingPolicy from "./components/ShippingPolicy";
import AllProductsData from "./screens/AllProductsData";
import InvoiceScreen from "./screens/InvoiceScreen"
import InvoiceListScreen from './screens/InvoiceListScreen'
import InvoiceCreateScreen from './screens/InvoiceCreateScreen'
import OnBoardStoreScreen from "./screens/OnBoardStoreScreen";
import OnBoardStoreListScreen from "./screens/OnBoardStoreListScreen";
import OnBoardStoreEditScreen from "./screens/OnBoardStoreEditScreen";

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route path="/" component={RetailHomeScreen} exact />
          <Fragment>
            <Header />
            <main className="py-2 mb-5">
              <Container>
                <Route path="/app" component={Homescreen} exact />
                <Route path="/onboard" component={OnBoardStoreScreen} exact />
                <Route path="/superadmin/storelist" component={OnBoardStoreListScreen} exact />
                <Route path="/superadmin/store/:id/edit" component={OnBoardStoreEditScreen} exact />

                <Route path="/page/:pageNumber" component={Homescreen} exact />
                <Route
                  path="/search/:keyword/page/:pageNumber"
                  component={Homescreen}
                  exact
                />
                <Route path="/product/:id" component={ProductScreen} />
                <Route path="/cart/:id?" component={CartScreen} />
                <Route path="/login" component={LoginScreen} />
                <Route path="/login/email" component={LoginEmail} />
                <Route path="/phoneSignUp" component={LoginPhone} />
                <Route path="/register" component={RegisterScreen} />
                <Route path="/profile" component={ProfileScreen} />
                <Route path="/shipping" component={ShippingScreen} />
                <Route path="/payment" component={PaymentScreen} />
                <Route path="/placeorder" component={PlaceOrderScreen} />
                <Route path="/order/:id" component={OrderScreen} />
                <Route path="/myorders" component={MyOrderScreen} />
                <Route path="/admin/userlist" component={UserListScreen} />
                <Route path="/admin/user/:id/edit" component={UserEditScreen} />
                <Route
                  path="/admin/productlist"
                  component={ProductListScreen}
                  exact
                />
                <Route
                  path="/admin/productlist/search/:keyword"
                  component={ProductListScreen}
                  exact
                />
                <Route
                  path="/admin/allProducts"
                  component={AllProductsData}
                  exact
                />
                <Route path="/admin/dashboard" component={DashBoard} exact />
                <Route
                  path="/admin/productlist/:pageNumber"
                  component={ProductListScreen}
                  exact
                />
                <Route
                  path="/admin/product/:id/edit"
                  component={ProductEditScreen}
                />
                <Route path="/admin/orderlist" component={OrderListScreen} exact />
                <Route path="/category/:category" component={Homescreen} exact />
                <Route
                  path="/admin/orderlist/:orderStatus/:pageNumber"
                  component={OrderListScreen}
                  exact
                />
                <Route path="/admin/cartscreen" component={CartScreenAdmin} />
                <Route path="/admin/invoice/:id/edit" component={InvoiceScreen} />
                <Route path="/admin/invoices" component={InvoiceListScreen} />
                <Route path="/admin/invoice/create" component={InvoiceCreateScreen} />

                <Route path="/search/:keyword" component={Homescreen} exact />
                <Route
                  path="/category/:category/page/:pageNumber"
                  component={Homescreen}
                  exact
                />
                <Route
                  path="/subcategory/:subcategory"
                  component={Homescreen}
                  exact
                />
                <Route
                  path="/subcategory/:subcategory/page/:pageNumber"
                  component={Homescreen}
                  exact
                />
                <Route path="/bundle/:id?" component={BundleScreen} exact />
                <Route path="/print/:id" component={BillingOrders} exact />
                <Route path="/privacypolicy" component={PrivacyPolicy} exact />
                <Route path="/refundpolicy" component={RefundPolicy} exact />
                <Route
                  path="/termsandconditions"
                  component={TermsAndConditions}
                  exact
                />
                <Route path="/shippingpolicy" component={ShippingPolicy} exact />
              </Container>
            </main>
            <Footer />
          </Fragment>
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default App;
