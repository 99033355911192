import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Product from "../components/Product";
import Message from "../components/Message";
import Loader from "../components/Loader";
import Paginate from "../components/Paginate";
import { listProducts } from "../actions/productActions";
import ProductCarousel from "../components/ProductCarousel";
import Meta from "../components/Meta";
import Category from "../components/Category";
import { Route } from "react-router-dom";
import NewArrivals from "../components/slider/NewArrivals";
import TopRated from "../components/slider/TopRated";
import TopSelling from "../components/slider/TopSelling";
import TopDeals from "../components/slider/TopDeals";
import SubCategory from "../components/SubCategory";
import { getStoreConfig } from "../actions/storeActions";
import env from 'dotenv'



env.config()
/* const apiServer = process.env.REACT_APP_API_SERVER
const storeId = process.env.REACT_APP_STORE_ID */

const Homescreen = ({ match }) => {
  const keyword = match.params.keyword;
  const category = match.params.category;
  const subCategory = match.params.subcategory;
  const pageNumber = match.params.pageNumber || 1;


  const dispatch = useDispatch();

  const productList = useSelector((state) => state.productList);
  const { loading, error, products, pages, page } = productList;

  const storeConfig = useSelector((state) => state.storeConfig);
  const { store } = storeConfig;

  document.title = store.name



  /*   document.querySelector('#store-manifest').setAttribute('href', `${apiServer}/api/config/${storeId}/manifest.json`); */


  useEffect(() => {
    dispatch(getStoreConfig());
    dispatch(listProducts(keyword, category, subCategory, pageNumber));
  }, [dispatch, keyword, category, subCategory, pageNumber]);

  return (
    <>
      {store.categories && (
        <>
          {!subCategory && !keyword && !category && (
            <div className="category">
              {store.categories
                .filter((category) => category.name !== "Please Select")
                .map((category) => (
                  <div key={category.id}>
                    <Route
                      render={({ history }) => (
                        <Category history={history} category={category} />
                      )}
                    />
                  </div>
                ))}
            </div>
          )}

          {store.categories
            .filter((category1) => category1.name === category)
            .map((category) => (
              <div className="sub-category">
                {category.subCategories.map((subCategory) => (
                  <div key={subCategory.id}>
                    <Route
                      render={({ history }) => (
                        <SubCategory
                          history={history}
                          subCategory={subCategory}
                        />
                      )}
                    />
                  </div>
                ))}
              </div>
            ))}
        </>
      )}
      <Meta />
      {store.bannerImages && (
        <>
          {!keyword && !category && !subCategory && <ProductCarousel banners={store.bannerImages} />}
        </>)}
      {!keyword && !category && !subCategory && (
        <TopDeals error={error} loading={loading} />
      )}
      {!keyword && !category && !subCategory && <TopSelling />}
      {!keyword && !category && !subCategory && (
        <NewArrivals error={error} loading={loading} products={products} />
      )}
      {!keyword && !category && !subCategory && <TopRated />}

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          {!keyword && !category && !subCategory && (
            <h1 className="carouselHeading"> ALL PRODUCTS </h1>
          )}
          <Row>
            {products.map((product) => (
              <Col key={product._id} xs={4} sm={4} md={3} lg={3} xl={2}>
                <Product product={product} id="product" />
              </Col>
            ))}
          </Row>

          <Paginate
            pages={pages}
            page={page}
            category={category ? category : ""}
            subCategory={subCategory ? subCategory : ""}
            keyword={keyword ? keyword : ""}
          />
        </>
      )}
      <br />
    </>
  );
};

export default Homescreen;
