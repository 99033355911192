import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Button,
  Row,
  Col,
  Modal,
  Form,
} from "react-bootstrap";
import { BsPhone, BsStopwatchFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { MdHandyman, MdContacts, MdPersonSearch } from "react-icons/md";
import { IoCreateOutline } from "react-icons/io5";
import { logout } from "../../actions/userActions";
import { BsCheckSquareFill } from "react-icons/bs";
import img from "./analysis.png";
import emailjs from "@emailjs/browser";
import "./about.css";
import { useState } from "react";

/* import '../index.css' */

const AboutUs = () => {
  const dispatch = useDispatch();
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [area, setArea] = useState("");
  const [modalShow, setModalShow] = React.useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const logoutHandler = () => {
    dispatch(logout());
  };

  const templateParams = {
    first_name: fname,
    last_name: lname,
    number: number,
    email: email,
    message: area,
    reply_to: email,
  };
  /* ----------------This is secrate key & service key & api key of email JS   */
  const submitHandler = () => {
    emailjs
      .send(
        "service_xhu1ho6",
        "template_wnpvbfi",
        templateParams,
        "Qi_k9iuIm8Yl8D4sT"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );
    setModalShow(true);
  };

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        style={{ color: "#000" }}
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ backgroundColor: "white" }} closeButton>
          <Modal.Title
            style={{ color: "green" }}
            id="contained-modal-title-vcenter"
          >
            Email Send Successfully <BsCheckSquareFill />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "white" }}>
          <h4>Our Team member will Contact You Soon</h4>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "white" }}>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <div>
      <header
        className="headerShadow"
        style={{ position: "sticky", top: "0", zIndex: "2" }}
      >
        <Navbar
          bg="dark"
          className="navbar-fixed-top"
          variant="dark"
          expand="md"
          collapseOnSelect
        >
          <Container>
            <LinkContainer to="/">
              <Navbar.Brand>
                <i className="fas fa-store"></i> Retail Center
              </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className=" mr-auto">
                <Nav.Link href="#home">
                  <IoCreateOutline /> Create Store
                </Nav.Link>
                <Nav.Link href="#about">
                  <MdPersonSearch /> About Us
                </Nav.Link>
                <Nav.Link href="#service">
                  <MdHandyman /> Service
                </Nav.Link>

                <Nav.Link href="#contact">
                  <MdContacts /> Contact
                </Nav.Link>
              </Nav>
              <Nav>
                {userInfo ? (
                  <NavDropdown title={userInfo.name} id="username">
                    <LinkContainer to="/profile">
                      <NavDropdown.Item>
                        <i className="fas fa-user-circle"></i> profile
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/myorders" id="myorders">
                      <NavDropdown.Item>
                        <i className="fas fa-list"></i> My Orders
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/" id="LogOut">
                      <NavDropdown.Item onClick={logoutHandler}>
                        <i className="fas fa-sign-out-alt"></i> Logout
                      </NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                ) : (
                  <LinkContainer to="/login" id="Login">
                    <Nav.Link>
                      <i className="fas fa-user"></i>Sign In
                    </Nav.Link>
                  </LinkContainer>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
      <Container>
        {/* ------------------------home how to create e-commerce----------- */}
        <section id="home">
          <Row className="p-2">
            <Col xs={8} md={10} lg={10}>
              <h3 className="retailHeading" style={{ color: "#000" }}>
                Create your mobile Friendly e-store within{" "}
                <b>
                  24 Hour <BsStopwatchFill size={20} />{" "}
                </b>{" "}
              </h3>
            </Col>
            <Col xs={4} md={2} lg={2} className="retailBtn mt-2">
              <a
                style={{
                  fontSize: "1.3rem",
                  backgroundColor: "#268bd2",
                  padding: "0.3rem 0.5rem",
                  color: "white",
                  borderRadius: "5px ",
                }}
                className="float-end mt-3 retailBtn"
                variant="secondary"
                href="https://app.retailcenter.io"
                target="_blank"
                rel="noreferrer"
              >
                Demo App <BsPhone size={20} />
              </a>
            </Col>
          </Row>

          {/*   <!-- -----------------About US ------------ --> */}
          <section style={{ color: "black" }} id="about">
            <div style={style.textcenter}>
              <span className="retailpara" style={{ color: "#000" }}>
                Who We Are ?
              </span>
              <span style={style.seperator}></span>
            </div>

            <Row className="row">
              <Col sm={12} xs={12} md={12} lg={6} className="mb-2 p-4">
                <div className="ratio ratio-4x3">
                  <iframe
                    title="About Over Company"
                    width="500"
                    height="500"
                    src="https://www.youtube.com/embed/4j4kizoMPkI"
                  >
                    {" "}
                  </iframe>
                </div>
              </Col>

              <Col
                sm={12}
                xs={12}
                md={12}
                lg={6}
                style={{ fontFamily: "merriweather, sans-serif" }}
                className="p-4 "
              >
                <p className="card-text">
                  <strong className="card-title"> Mission : </strong> Our mission
                  is to digitilize retaile outlets , small or big , to be able to
                  increase their operational effeciency thereby enhancing Sales
                  and Customer Service.
                </p>
                <br />
                <p>
                  <strong>Vision : </strong> Our Visison is to make digitilisation
                  affordable and accesable to one and all by building cost
                  effective ,high quality digital solutions.
                </p>
                <br />
                <p className="card-text">
                  <strong>Product : </strong>
                  <b style={{ color: " #2aa198" }}> Retail Center</b> is a
                  digital solution that helps you to manage your retail outlet. It
                  helps you to manage your inventory, sales, and customer service.
                </p>
                <br />
                <p className="card-text">
                  <strong>Team : </strong> We are a team of digital developers and
                  designers who are passionate about digital solutions. We are
                  here to help you to build a better digital presence for your
                  business.
                </p>
                <br />
                <p className="card-text">
                  <strong>Technology :</strong>Secure, Scalable, User Friendly and
                  reliable technology is the key to our success. We use the{" "}
                  <b>MERN</b> stack to Build and Host on <b>AWS </b>and
                  <b> GCP</b> make sure that your business is secure and reliable.
                </p>
              </Col>
            </Row>
          </section>

        </section>

        {/*   <!-- -----------------Features  ------------ --> */}
        <section style={{ color: "black" }} id="about">
          <div style={style.textcenter}>
            <span className="retailpara" style={{ color: "#000" }}>
              Features
            </span>
            <span style={style.seperator}></span>
          </div>
          <Row style={{ textAlign: "center" }} className="mb-2  mt-2">
            <Col xs={12} md={12} lg={10} className="mx-auto p-3">
              <img style={{ width: "100%" }} src={img} alt="img" />
            </Col>
          </Row>
        </section>
        {/*   <!-- -----------------How partner  ------------ --> */}
        <section style={{ color: "black" }} id="about">
          <div style={style.textcenter}>
            <span className="retailpara" style={{ color: "#000" }}>
              How to Partner?
            </span>
            <span style={style.seperator}></span>
          </div>

          <section className="container mt-2 mb-5">
            {/*   <div style={style.textcenter}>
              <span className="retailpara" style={{ color: "#000" }}>
                Create your mobile friendly e-store within 24 hour
                <b>
                  <BsStopwatchFill size={20} />{" "}
                </b>{" "}
              </span>
              <span style={style.seperator}></span>
            </div> */}
            <Row className="row" style={{ justifyContent: "space-evenly" }}>
              {userInfo ? (
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span>step 1</span>
                  <div className="border  p-3 cardHead">
                    <h2 style={style.info}>
                      <strong>Loged In</strong>
                    </h2>

                    <div className="d-grid gap-1 col-11 mx-auto">
                      <a
                        className="btn btn-secondary buttonStyle disabledLogin"
                        href="/login"
                      >
                        Loged In
                      </a>
                    </div>
                  </div>
                </Col>
              ) : (
                <Col xs={12} md={6} lg={4} className=" mb-3">
                  <span>step 1</span>
                  <div className="border  p-3 cardHead">
                    <h2 style={style.info}>
                      <strong>Sign In</strong>
                    </h2>

                    <div className="d-grid gap-1 col-11 mx-auto">
                      <a
                        className="btn btn-secondary buttonStyle"
                        href="/login"
                      >
                        Sign In
                      </a>
                    </div>
                  </div>
                </Col>
              )}
              <Col xs={12} md={6} lg={4} className="mb-3">
                <span> step 2</span>
                <div className="border p-3 cardHead">
                  <h2 style={style.info}>
                    <strong>Fill Details</strong>
                  </h2>

                  <div className="d-grid gap-1 col-11 mx-auto">
                    <a
                      className="btn btn-secondary buttonStyle"
                      href="/onboard"
                    >
                      On Boarding
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="mb-3">
                <span> step 3</span>
                <div className="border p-3 cardHead">
                  <h2 style={style.info}>
                    <strong>
                      Setup Discounts ,Banners and logo with Our Customer
                      Scucces Team{" "}
                    </strong>
                  </h2>
                </div>
              </Col>
            </Row>
          </section>

        </section>
        {/* <!--------------------service section----------------------> */}
        <section section className="container mt-5 mb-5" id="service">
          <div style={style.textcenter}>
            <span className="retailpara" style={{ color: "#000" }}>
              Products & Plans
            </span>
            <span style={style.seperator}></span>
            {/*  <!-- <h1>Drop Us a Mail</h1>--> */}
          </div>
          <Row className="row" style={{ justifyContent: "space-evenly" }}>
            <Col sm={12} xs={12} md={6} lg={4} className="mb-3">
              <div className="border pt-3 pb-3 cardHead">
                <h2 style={style.card}>
                  <strong>Basic</strong>
                </h2>
                <ul className="card-text">
                  <li>
                    Billing <i className="fas fa-check fasCheck"></i>
                  </li>
                  <li>
                    Inventory <i className="fas fa-check fasCheck"></i>
                  </li>
                  <li>
                    Order Management <i className="fas fa-check fasCheck"></i>
                  </li>
                  <li>Kiosk</li>
                  <li>Multi desk (counter)</li>
                  <li>Accounting</li>
                  <li>Analytics</li>
                  <li>Online Marketing</li>
                  <li>E-mail Support or Call</li>
                  <li>24x7 Support</li>
                  <li>3000 Orders  <i className="fas fa-check fasCheck"></i></li>
                  <li>Price  <span > ₹ 3000/Year</span>  <i className="fas fa-check fasCheck"></i></li>
                </ul>
                <div className="d-grid gap-1 col-11 mx-auto">
                  <a className="btn btn-secondary buttonStyle" href="#contact">
                    Contact Us
                  </a>
                </div>
              </div>
            </Col>
            <Col sm={12} xs={12} md={6} lg={4} className="mb-3">
              <div className="border pt-3 pb-3 cardHead">
                <h2 style={style.card}>
                  <strong>Pro</strong>
                </h2>

                <ul className="card-text">
                  <li>
                    Billing <i className="fas fa-check fasCheck"></i>
                  </li>
                  <li>
                    Inventory <i className="fas fa-check fasCheck"></i>
                  </li>
                  <li>
                    Order Management <i className="fas fa-check fasCheck"></i>
                  </li>
                  <li>
                    Kiosk <i className="fas fa-check fasCheck"></i>
                  </li>
                  <li>
                    Multi desk (counter){" "}
                    <i className="fas fa-check fasCheck"></i>
                  </li>
                  <li>
                    Accounting <i className="fas fa-check fasCheck"></i>
                  </li>
                  <li>
                    Analytics <i className="fas fa-check fasCheck"></i>
                  </li>
                  <li>Online Marketing</li>
                  <li>E-mail Support or Call</li>
                  <li>24x7 Support</li>
                  <li>15,000 Orders  <i className="fas fa-check fasCheck"></i></li>
                  <li>Price  <span > ₹ 10,000/Year</span>  <i className="fas fa-check fasCheck"></i></li>
                </ul>
                <div className="d-grid gap-1 col-11 mx-auto">
                  <a className="btn btn-secondary buttonStyle" href="#contact">
                    Contact Us
                  </a>
                </div>
              </div>
            </Col>
            <Col sm={12} xs={12} md={6} lg={4} className="mb-3">
              <div className="border pt-3 pb-3 cardHead">
                <h2 style={style.card}>
                  <strong> Enterprise</strong>
                </h2>
                <ul>
                  <li>
                    Billing <i className="fas fa-check fasCheck"></i>
                  </li>
                  <li>
                    Inventory <i className="fas fa-check fasCheck"></i>
                  </li>
                  <li>
                    Order Management <i className="fas fa-check fasCheck"></i>
                  </li>
                  <li>
                    Kiosk <i className="fas fa-check fasCheck"></i>
                  </li>
                  <li>
                    Multi desk (counter){" "}
                    <i className="fas fa-check fasCheck"></i>
                  </li>
                  <li>
                    Accounting <i className="fas fa-check fasCheck"></i>
                  </li>
                  <li>
                    Analytics <i className="fas fa-check fasCheck"></i>
                  </li>
                  <li>
                    Online Marketing <i className="fas fa-check fasCheck"></i>
                  </li>
                  <li>
                    E-mail Support or Call{" "}
                    <i className="fas fa-check fasCheck"></i>
                  </li>
                  <li>
                    24x7 Support <i className="fas fa-check fasCheck"></i>
                  </li>
                  <li>More then 10 lakh  Orders  <i className="fas fa-check fasCheck"></i></li>
                  <li>Call Us  <i className="fas fa-check fasCheck"></i></li>
                </ul>
                <div className="d-grid gap-1 col-11 mx-auto">
                  <a className="btn btn-secondary buttonStyle" href="#contact">
                    Contact Us
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </section>
        {/* <!--FORM SECTION--> */}
        <div id="contact">
          <section style={style.section1} className="section1 clearfix">
            <div style={style.textcenter}>
              <span className="retailpara" style={{ color: "#000" }}>
                Contact Us
              </span>
              <span style={style.seperator}></span>
            </div>
          </section>

          <section className="section2 clearfix">
            <div className="col2 column2 first">
              <div className="sec2contactform">
                <h3 className="sec2frmtitle">
                  Want to Know More? Drop Us a Mail
                </h3>

                <Form onSubmit={submitHandler}>
                  <div className="clearfix">
                    <input
                      className="col2 first"
                      value={fname}
                      required
                      onChange={(e) => setFname(e.target.value)}
                      type="text"
                      placeholder="FirstName*"
                    />
                    <input
                      className="col2 last"
                      value={lname}
                      required
                      onChange={(e) => setLname(e.target.value)}
                      type="text"
                      placeholder="LastName*"
                    />
                  </div>
                  <div className="clearfix">
                    <input
                      className="col2 first"
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      placeholder="Email Address*"
                    />
                    <input
                      className="col2 last"
                      value={number}
                      required
                      onChange={(e) => setNumber(e.target.value)}
                      type="text"
                      placeholder="Contact Number*"
                    />
                  </div>
                  <div className="clearfix">
                    <textarea
                      placeholder="Your message here..."
                      value={area}
                      onChange={(e) => setArea(e.target.value)}
                      cols="30"
                      rows="7"
                    ></textarea>
                  </div>
                  <div>
                    <input type="submit" value="Submit" />
                  </div>
                </Form>
                <MyVerticallyCenteredModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
                {/* <form class="row g-3">
                  <div class="col-md-6">
                    <label for="inputEmail4" class="form-label">Email</label>
                    <input type="email" class="form-control" id="inputEmail4" />
                  </div>
                  <div class="col-md-6">
                    <label for="inputPassword4" class="form-label">Password</label>
                    <input type="password" class="form-control" id="inputPassword4" />
                  </div>
                  <div class="col-12">
                    <label for="inputAddress" class="form-label">Address</label>
                    <input type="text" class="form-control" id="inputAddress" placeholder="1234 Main St" />
                  </div>
                  <div class="col-12">
                    <label for="inputAddress2" class="form-label">Address 2</label>
                    <input type="text" class="form-control" id="inputAddress2" placeholder="Apartment, studio, or floor" />
                  </div>
                  <div class="col-md-6">
                    <label for="inputCity" class="form-label">City</label>
                    <input type="text" class="form-control" id="inputCity" />
                  </div>
                  <div class="col-md-4">
                    <label for="inputState" class="form-label">State</label>
                    <select id="inputState" class="form-select">
                      <option selected>Choose...</option>
                      <option>...</option>
                    </select>
                  </div>
                  <div class="col-md-2">
                    <label for="inputZip" class="form-label">Zip</label>
                    <input type="text" class="form-control" id="inputZip" />
                  </div>
                  <div class="col-12">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="gridCheck" />
                      <label class="form-check-label" for="gridCheck">
                        Check me out
                      </label>
                    </div>
                  </div>
                  <div class="col-12">
                    <button type="submit" class="btn btn-primary">Sign in</button>
                  </div>
                </form> */}
              </div>
            </div>

            <div className="col2 column1 last">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe
                    title="company location"
                    className="gmap_iframe"
                    width="100%"
                    scrolling="no"
                    src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=mufeed product and services chitguppa&amp;t=k&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  ></iframe>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Container>
      {/*  <!-- Footer --> */}
      <section id="about">
        <footer
          className="text-center text-lg-start text-muted mt-5"
          style={{ backgroundColor: "#EDEDEd" }}
        >
          <div className="container text-center text-md-start ">
            {/* <!-- Right --> */}

            <Row className="row mt-3 ">
              {/*             <!-- Grid column -->
               */}{" "}
              <Col sm={12} xs={12} md={6} lg={4} className="mx-auto ">
                {/*               <!-- Content -->
                 */}{" "}
                <h6 className="text-uppercase fw-bold mb-2  mt-4 colorHeading">
                  <i className="fas fa-gem me-3"></i>Company Details
                </h6>
                <p className="color">
                  We are a registered Startup under Startup India Program with
                  Focus on Innvoative Solutions to help not just Enterprises but
                  Local bussinesses as well
                </p>
              </Col>
              <Col sm={12} xs={12} md={6} lg={2} className="mx-auto mb-4">
                {/*               <!-- Links -->
                 */}{" "}
                <h6 className="text-uppercase fw-bold mb-2 mt-4 colorHeading">
                  Product Services
                </h6>
                <p>
                  <a href="#service" className="color">
                    Basic
                  </a>
                </p>
                <p>
                  <a href="#service" className=" color">
                    Pro
                  </a>
                </p>
                <p>
                  <a href="#service" className=" color">
                    Enterprise
                  </a>
                </p>
              </Col>
              {/*             <!-- Grid column -->
               */}
              {/*             <!-- Grid column -->
               */}{" "}
              <Col sm={12} xs={12} md={6} lg={3} className="mx-auto mb-4">
                {/*               <!-- Links -->
                 */}{" "}
                <h6 className="text-uppercase fw-bold mb-2 mt-4 colorHeading">
                  Contact US
                </h6>
                <p>
                  <a
                    className="fas fa-home me-3 color"
                    href="https://www.google.com/maps/place/MUFEED+PRODUCTS+AND+SERVICES/@17.698906,77.216225,6710m/data=!3m1!1e3!4m5!3m4!1s0x0:0xf7ce44c862937587!8m2!3d17.6989027!4d77.2162992?hl=en"
                  >
                    {" "}
                    Chitguppa, 585412, India
                  </a>
                </p>
                <p>
                  <a
                    href="mailto:mufeez@retailcenter.io"
                    className="fas fa-envelope me-3 color"
                  >
                    {" "}
                    mufeez@retailcenter.io
                  </a>
                </p>
                <p>
                  <a
                    href="tel:+918861710458"
                    className="fas fa-phone me-3 color"
                  >
                    {" "}
                    +918861710458{" "}
                  </a>
                </p>
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="mx-auto mb-4">
                {/*               <!-- Links -->
                 */}{" "}
                <h6 className="text-uppercase fw-bold mb-2 mt-4  colorHeading">
                  Social media
                </h6>
                <div>
                  <div
                    style={{
                      display: "inline-block",
                      marginRight: "0.7rem",
                      textAlign: "center",
                    }}
                  >
                    <a
                      className="btn btn-secondary btn-floating  "
                      rel="noreferrer"
                      target="_blank"
                      style={{ backgroundColor: "#55acee" }}
                      href="https://twitter.com/mufeez1"
                      role="button"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                    <div>
                      <span>Twitter</span>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "inline-block",
                      marginRight: "0.7rem",
                      textAlign: "center",
                    }}
                  >
                    <a
                      className="btn btn-secondary btn-floating "
                      rel="noreferrer"
                      target="_blank"
                      style={{ backgroundColor: "#0082ca" }}
                      href="https://www.linkedin.com/in/mufeezahmed/"
                      role="button"
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                    <div> Linkedin</div>
                  </div>

                  <div
                    style={{
                      display: "inline-block",
                      marginRight: "0.7rem",
                      textAlign: "center",
                    }}
                  >
                    <a
                      className="btn btn-secondary  btn-floating "
                      rel="noreferrer"
                      target="_blank"
                      style={{ backgroundColor: "#211", borderRadius: "50%" }}
                      href="https://github.com/Mufeez"
                      role="button"
                    >
                      <i className="fab fa-github"></i>
                    </a>
                    <div> Github</div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={11}
                lg={12}
                xl={12}
                className="footerStyle"
              >
                <p>
                  {" "}
                  Copyright &copy; Huzaifa Software Solutions Private Limited{" "}
                </p>
              </Col>
            </Row>
          </div>
        </footer>
      </section>
      {/*     <!-- -----js bootstap----- -->
       */}{" "}
      <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
        crossOrigin="anonymous"
      ></script>
    </div>
  );
};

export default AboutUs;

const style = {
  textcenter: {
    textAlign: "center",
  },
  cardHead: {
    borderRadius: "1px solid #000",
    li: {
      fontSize: "20px",
    },
  },
  card: {
    textAlign: "center",
    color: "#2aa198",
    fontFamily: "merriweather, sans-serif",
  },
  info: {
    fontSize: "1.5rem",
    textAlign: "center",
    color: "#2aa198",
    fontFamily: "merriweather, sans-serif",
  },
  seperator: {
    borderBottom: "1px solid #a2a2a2",
    width: "35px",
    display: "inline-block",
    margin: "5px 30px 30px 30px",
  },
  section1: {
    textAlign: "center",
    display: "table",
    width: "100%",
    ".clearfix:before,\n.clearfix:after": { display: "table", content: "''" },
    ".clearfix:after": { clear: "both" },
  },
  clearfix: {
    ".clearfix:before,\n.clearfix:after": { display: "table", content: "''" },
    ".clearfix:after": { clear: "both" },
  },
};
