import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listUsers, deleteUsers } from '../actions/userActions'

const UserListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const userList = useSelector((state) => state.userList)
  const { loading, error, users } = userList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userDelete = useSelector((state) => state.userDelete)
  const { success: successDelete } = userDelete

  useEffect(() => {
    if (userInfo && userInfo.role.includes('admin')) {
      dispatch(listUsers())
    } else {
      history.push('/login')
    }
  }, [dispatch, history, successDelete, userInfo])

  const deleteHandler = (id) => {
    if (window.confirm('Are You Sure')) {
      dispatch(deleteUsers(id))
    }
  }
  return (
    <>
      <h1>Users</h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='warning'>{error}</Message>
      ) : (
        <>
          <Row className='tableHead '>
            <Col sm={3} md={3}>
              ID
            </Col>
            <Col sm={3} md={3}>
              NAME
            </Col>
            <Col sm={3} md={3}>
              EMAIL
            </Col>
            <Col sm={1} md={1}>
              ADMIN
            </Col>
            <Col sm={1} md={1}>
              EIDIT
            </Col>
            <Col sm={1} md={1}>
              DELETE
            </Col>
          </Row>
          {users.map((user) => (
            <Row className='tableData ' key={user._id}>
              <Col sm={3} md={3}>
                {user._id}
              </Col>
              <Col sm={3} md={3}>
                {user.name}
              </Col>
              <Col sm={3} md={3}>
                <a href={`mailto:${user.email}`}>{user.email}</a>
              </Col>
              <Col sm={1} md={1}>
                {user.role ? <i className='fas fa-check' style={{ color: 'green' }}></i> : <i className='fas fa-times' style={{ color: 'red' }}></i>}{' '}
              </Col>
              <Col sm={1} md={1}>
                <LinkContainer to={`/user/edit/${user._id}`}>
                  <Button variant='light' className='btn-md'>
                    <i className='fas fa-edit'></i>
                  </Button>
                </LinkContainer>
              </Col>
              <Col sm={1} md={1}>
                <Button variant='light' style={{ color: 'red' }} className='btn-md' onClick={() => deleteHandler(user._id)}>
                  <i className='fas  fa-trash'></i>
                </Button>
              </Col>
            </Row>
          ))}
        </>
      )}
    </>
  )
}

export default UserListScreen
