import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, ListGroup, Card, Button, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import {
  getOrderDetails,
  printOrder,
  payOrder,
  deliverOrder,
} from "../actions/orderActions";
import BillingInvoice from "../components/BillingInvoice";
import GstBillingInvoice from "../components/GstBillingInvoice";

const OrderScreen = ({ match }) => {
  const orderId = match.params.id;

  const dispatch = useDispatch();

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;

  const orderPay = useSelector((state) => state.orderPay);
  const { loading: loadingPay, error: errorPay } = orderPay;

  const orderDeliver = useSelector((state) => state.orderDeliver);
  const { loading: loadingDeliver, error: errorDeliver } = orderDeliver;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  if (!loading) {
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2);
    };
    order.itemsPrice = addDecimals(
      order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0)
    );
  }

  const printOrderReceipt = () => {
    dispatch(printOrder(order));
  };

  useEffect(() => {
    dispatch(getOrderDetails(orderId));
  }, [dispatch, orderId]);

  const paidHandler = () => {
    dispatch(payOrder(order));
  };

  const deliverHandler = () => {
    dispatch(deliverOrder(order));
  };

  /*  const addRazorPayScript = async () => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onerror = () => {
      alert("Razorpay SDK failed to load. Are you online?");
    };
    script.onload = async () => {
      try {
        setRazorPayLoading(true);
        const result = await axios.post("/api/payment/create-order", {
          amount: 5000,
        });
        const { amount, id: order_id, currency } = result.data;
        const {
          data: { key: razorpayKey },
        } = await axios.get("/api/payment/get-razorpay-key");

        const options = {
          key: razorpayKey,
          amount: amount.toString(),
          currency: currency,
          name: "Order Details",
          description: "Total Amount to be paid",
          order_id: order_id,
          handler: async function (response) {
            const result = await axios.post("/pay-order", {
              amount: amount,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
            });
            paidHandler();
            alert(result.data.msg);
          },
          prefill: {
            name: userInfo.name,
            email: userInfo.email,
            contact: userInfo.phone,
          },
          notes: {
            address: order.shippingAddress.address,
          },
          theme: {
            color: "#2aa198",
          },
        };

        setRazorPayLoading(false);
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } catch (err) {
        alert(err);
        setRazorPayLoading(false);
      }
    };
    document.body.appendChild(script);
  }; */

  /* -------------TO DO-Notification ---------------- */
  /* send whatsapp message from react js */
  /* const sendMessage = () => {
    const message = `Hi ${userInfo.name},\n\nThank you for shopping with us.\n\nYour order has been placed successfully.\n\nOrder Id: ${order._id}\n\nOrder Items:\n${order.orderItems
      .map((item) => `${item.name} - ${item.qty} x ${item.price} = ${item.price * item.qty}`)
      .join('\n')}\n\nTotal: ${order.itemsPrice}\n\nDelivery Address:\n${order.address}\n\nDelivery Date: ${order.deliveryDate}\n\nDelivery Time: ${order.deliveryTime}\
    \n\nThank you for shopping with us.\n\nRegards,\n\nTeam ${userInfo.name}`
    const url = `https://api.whatsapp.com/send?phone=+91${order.shippingAddress.phone}&text=${message}`
    window.open(url, '_blank')
  } */

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant="light">{error}</Message>
  ) : (
    <>
      <h4 id="orderId">Order Id:{order._id}</h4>
      <Row>
        <Col xs={12} md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2>Shipping Address </h2>
              <p>
                <strong>Name: </strong> {order.user.name}
              </p>
              <p>
                <strong>Email: </strong>
                <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
              </p>
              <p>
                <strong>Address: </strong>
                {order.shippingAddress.phone} ,{order.shippingAddress.address} ,
                {order.shippingAddress.city} ,{order.shippingAddress.pinCode} ,
                {order.shippingAddress.country}
              </p>
              {order.isDelivered ? (
                <Message variant="success">
                  Delivered On: {order.deliveredAt.substring(0, 10)}
                </Message>
              ) : (
                <Message variant="light">Not Delivered</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Payment Method</h2>
              <Row className="mb-4">
                <Col xs={12}>
                  <strong>Method: </strong>
                  {order.paymentMethod}
                </Col>
                <Col xs={6} md="auto">
                  <BillingInvoice />
                </Col>
                <Col xs={6} md="auto">
                  <GstBillingInvoice />
                </Col>
                {userInfo.role.includes("admin") && (
                  <Col>
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={printOrderReceipt}
                    >
                      Print
                    </Button>
                  </Col>
                )}
              </Row>
              {order.isPaid ? (
                <Message variant="success">
                  Paid On: {order.paidAt.substring(0, 10)}
                </Message>
              ) : (
                <Message variant="light">Not Paid</Message>
              )}
            </ListGroup.Item>
            <ListGroup.Item>
              <h1>Order Items</h1>
              {order.orderItems.length === 0 ? (
                <Message>No Orders</Message>
              ) : (
                <ListGroup variant="flush">
                  {order.orderItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col xs={2} md={1}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col xs={3}>
                          <Link to={`/product/${item.product}`}>
                            {item.name}
                          </Link>
                        </Col>
                        <Col xs={7} md={4}>
                          {item.qty} X ₹{item.price} = ₹ {item.qty * item.price}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col className="mb-5" xs={12} md={4}>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2>Order Summary</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Items :</Col>
                  <Col>₹ {order.itemsPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Shipping :</Col>
                  <Col>
                    {order.itemsPrice > 250 ? "FREE DELIVERY" : `₹ ${10}`}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Total Amount:</Col>
                  <Col>₹ {order.totalPrice}</Col>
                </Row>
              </ListGroup.Item>
              {loadingDeliver && <Loader />}
              {loadingPay && <Loader />}
              {errorPay && <Message variant="danger">{errorPay}</Message>}
              {errorDeliver && (
                <Message variant="danger">{errorDeliver}</Message>
              )}
              {userInfo.role.includes("admin") && !order.isPaid && (
                <ListGroup.Item className="d-grid gap-2">
                  <Button
                    size="lg"
                    type="button"
                    variant="dark"
                    className="btn btn-block textSizeM"
                    onClick={paidHandler}
                  >
                    Mark As Paid
                  </Button>
                </ListGroup.Item>
              )}
              {userInfo.role.includes("admin") &&
                !order.isDelivered &&
                order.isPaid && (
                  <ListGroup.Item className="d-grid gap-2">
                    <Button
                      size="lg"
                      type="button"
                      variant="dark"
                      className="btn btn-block textSizeM"
                      onClick={deliverHandler}
                    >
                      Mark As Delivered
                    </Button>
                  </ListGroup.Item>
                )}
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default OrderScreen;
