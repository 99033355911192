import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import { saveShippingAddress } from "../actions/cartActions";
import CheckoutSteps from "../components/CheckoutSteps";
import UseGeolocation from "../components/UseGeolocation";
import axios from "axios";
import env from "dotenv";

env.config();

const ShippingScreen = ({ history }) => {
  /* const cart = useSelector((state) => state.cart)
  const { shippingAddress } = cart */
  const [address, setAddress] = useState("");
  const [pinCode, setPinCode] = useState("");
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const location = UseGeolocation();

  const phone = userInfo.phoneNumber;

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !location.error &&
      location.loaded &&
      location.coordinates.lat !== "" &&
      location.coordinates.lng !== ""
    ) {
      const option = {
        method: "POST",
        url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.coordinates.lat},${location.coordinates.lng}&key=${process.env.REACT_APP_GEO_LOCATION_API_KEY}`,
      };

      axios
        .request(option)
        .then((response) => {
          const address = response.data.results[0].formatted_address;
          setAddress(address);
          setPinCode(
            response.data.results[0].address_components[
              response.data.results[0].address_components.length - 1
            ].long_name
          );
        })
        .catch(function (error) {
          console.error(error);
        });
    }
  }, [
    location.coordinates.lat,
    location.coordinates.lng,
    location.loaded,
    location.error,
  ]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(saveShippingAddress({ phone, address, pinCode }));
    history.push("/payment");
  };

  return (
    <FormContainer>
      <CheckoutSteps step1 step2 />
      <h1>Shipping</h1>
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="address">
          <Form.Label>Address </Form.Label>

          <Form.Control
            type="text"
            name="address-one"
            autoComplete="address-one"
            placeholder="Enter Your Address"
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="pinCode">
          <Form.Label>Pin Code </Form.Label>
          <Form.Control
            autoComplete="postal-code"
            name="postal-code"
            type="text"
            placeholder="Enter Your Pin Code"
            value={pinCode}
            onChange={(e) => setPinCode(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <br />
        <Button size='lg' type="submit" id="payment" variant="secondary">
          Continue To Payment
        </Button>
      </Form>
    </FormContainer>
  );
};

export default ShippingScreen;
