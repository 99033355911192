import React from "react";

const UseGeolocation = () => {
  const [location, setLocation] = React.useState({
    loaded: false,
    coordinates: { lat: "", lng: "" },
  });

  const onSuccess = (position) => {
    setLocation({
      loaded: true,
      coordinates: {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      },
    });
  };

  const onError = (error) => {
    setLocation({
      loaded: true,
      coordinates: {
        lat: "",
        lng: "",
      },
      error,
    });
  };

  if (!("geolocation" in navigator)) {
    onError({
      code: 0,
      message: "Geolocation is not supported by this browser",
    });
  }
  navigator.geolocation.getCurrentPosition(onSuccess, onError, {
    maximumAge: 60000,
    timeout: 5000,
    enableHighAccuracy: true,
  });
  return location;
};

export default UseGeolocation;
