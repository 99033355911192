import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div style={{ display: window.innerWidth < 450 ? "none" : "block" }}>
      <Container style={{ textAlign: "center" }}>
        <Row
          style={{ justifyContent: "center", height: "20px" }}
          className=" mb-5"
        >
          <Col
            xs={12}
            sm={12}
            md={11}
            lg={10}
            xl={10}
            className=" text-center footerPolicy fst-italic fw-bolder "
          >
            <Link to="/privacypolicy">
              <p>Privacy Policy</p>
            </Link>
            <Link to="/termsandconditions">
              <p> Terms & Conditions</p>
            </Link>
            <Link to="/aboutus">
              <p>About Us</p>
            </Link>
            <Link to="/refundpolicy">
              <p> Refund Policy</p>
            </Link>
            <Link to="/shippingpolicy">
              <p>Shipping Policy</p>
            </Link>{" "}
            <p>
              <a
                className="fas fa-home me-3"
                href="https://www.google.com/maps/place/MUFEED+PRODUCTS+AND+SERVICES/@17.698906,77.216225,6710m/data=!3m1!1e3!4m5!3m4!1s0x0:0xf7ce44c862937587!8m2!3d17.6989027!4d77.2162992?hl=en"
              >
                {" "}
                15-92 Chitguppa, 585412, India
              </a>
            </p>
            <p>
              <a
                href="mailto:mufeez@retailcenter.io"
                className="fas fa-envelope "
              >
                {" "}
                mufeez@retailcenter.io
              </a>
            </p>
            <p>
              <a href="tel:+918861710458" className="fas fa-phone ">
                {" "}
                +918483458427{" "}
              </a>
            </p>
          </Col>
        </Row>
        <Row
          style={{ justifyContent: "center", height: "20px" }}
          className=" mb-5"
        >
          <Col
            xs={12}
            sm={12}
            md={11}
            lg={10}
            xl={6}
            className=" text-center"
          ></Col>
        </Row>
        <Row style={{ justifyContent: "center" }}>
          <Col
            xs={12}
            sm={12}
            md={11}
            lg={10}
            xl={6}
            className=" py-1 footer mx-auto"
          >
            Copyright &copy; Huzaifa Software Solutions Private Limited <br />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
