import React, { useEffect } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listMyOrders } from '../actions/orderActions'

const MyOrderScreen = ({ history }) => {
  const dispatch = useDispatch()

  const userDetails = useSelector((state) => state.userDetails)
  const { user } = userDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const orderListMy = useSelector((state) => state.orderListMy)
  const { loading, error, orders } = orderListMy

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else {
      if (!user.name) {
        dispatch(listMyOrders())
      }
    }
  }, [history, userInfo, dispatch, user])

  return (
    <>
      <h1> My Orders </h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='warning'>{error}</Message>
      ) : (
        <>
          <Row style={{ color: 'black', fontWeight: 'bold', textAlign: 'center', verticalAlign: ' middle', lineHeight: '30px' }} className='justify-content-center mb-3'>
            <Col lg={2} md={4} xs={4}>
              Photo
            </Col>
            <Col xs={2}>Paid</Col>
            <Col xs={3}>Delivered</Col>
            <Col xs={3}>Action</Col>
          </Row>
          {orders.map((order) => (
            <Row style={{ textAlign: 'center', verticalAlign: ' middle' }} className='justify-content-center mb-2'>
              <Col lg={2} md={4} xs={4}>
                <LinkContainer to={`/order/${order._id}`}>
                  <img src={order.orderItems[0].image} alt={order.orderItems[0].name} width='50%' />
                </LinkContainer>
              </Col>
              <Col xs={2}>{order.isPaid ? <i className='fas fa-check' style={{ color: 'green' }}></i> : <i className='fas fa-times' style={{ color: 'red' }}></i>} </Col>
              <Col xs={3}> {order.isDelivered ? <i className='fas fa-check' style={{ color: 'green' }}></i> : <i className='fas fa-times' style={{ color: 'red' }}></i>}</Col>
              <Col xs={3}>
                <LinkContainer to={`/order/${order._id}`}>
                  <Button variant='primary'>Details</Button>
                </LinkContainer>
              </Col>
            </Row>
          ))}
        </>
      )}
    </>
  )
}

export default MyOrderScreen
