import { React, useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import ProductCard from "./ProductCard";
import axios from "axios";
import env from 'dotenv'

env.config()
const Bundle = ({ bundle, history }) => {
  const [productsdetails, setProducts] = useState();
  const addToCartHandler = () => {
    history.push(`/cart/${bundle._id}?qty=1&type=bundle`);
  };

  console.log(bundle._id);

  useEffect(() => {
    const getBundle = async () => {
      const storeId = process.env.REACT_APP_STORE_ID;
      const productsdetails = await bundle.products.reduce(
        async (acc, product) => {
          const { data } = await axios.get(
            `/api/store/${storeId}/products/${product}`
          );
          return [...(await acc), data];
        },
        []
      );

      setProducts(productsdetails);
    };
    getBundle();
  }, [bundle.products]);

  return (
    <div style={{ margin: "1rem" }}>
      <h3 style={{ margin: "0" }}>{bundle.name}</h3>
      {productsdetails && (
        <Row>
          {productsdetails.map((product, index) => (
            <Col key={index} xs={3} sm={6} md={4} lg={3} xl={1.5}>
              <ProductCard product={product} id="product" />
            </Col>
          ))}
        </Row>
      )}
      <div className="d-flex align-items-center justify-content-center mb-1">
        <Button
          variant="secondary"
          className={"mt-2"}
          onClick={addToCartHandler}
        >
          Add All Products To Cart
        </Button>
      </div>
    </div>
  );
};

export default Bundle;
