import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { OrdersDoughnut } from '../charts/OrdersDoughnut'
import { TopSellsBar } from '../charts/TopSellsBar'
import { LessTenProducts } from '../charts/LessTenProducts'
import { SaleReportChart } from '../charts/SaleReportChart'
import { OrdersSummary } from '../charts/OrdersSummary' /* 
import { CardData } from '../charts/CardData' */

const DashBoard = () => {
  /*  const groupBy = 'day' */
  return (
    <>
      {/* <CardData /> */}
      <Row style={{  marginBottom: '2rem' }}>
        <Col xs={12} md={3}>
          
          <OrdersSummary />
        </Col>
        <Col xs={12} md={6}>
          <SaleReportChart />
        </Col>
        <Col xs={12} md={3}>
          <OrdersDoughnut />
        </Col>
        </Row>
        <Row>
          
        <Col xs={12} md={6}>
          <TopSellsBar />
        </Col>
        
        <Col xs={12} md={3}>
          <LessTenProducts />
        </Col>
      </Row>
    </>
  )
}

export default DashBoard
