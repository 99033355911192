import React, { useState } from 'react'
import { Form, Button, Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import FormContainer from '../components/FormContainer'
import { savePaymentMethod } from '../actions/cartActions'
import CheckoutSteps from '../components/CheckoutSteps'

const PaymentScreen = ({ history }) => {

    const [paymentMethod, setPaymentMethod] = useState('Cash')

    const dispatch = useDispatch()
    console.log(paymentMethod);


    const submitHandler = async (e) => {
        e.preventDefault()
        dispatch(savePaymentMethod(paymentMethod))
        history.push('/placeorder')
    }
    /*   dispatch(savePaymentMethod(paymentMethod))
      history.push('/placeorder') */


    return (
        <FormContainer>
            <CheckoutSteps step1 step2 step3 />
            <h1>Payment Method</h1>
            <Form >
                <Form.Group >
                    <Form.Label as='legend'>Select Method</Form.Label>
                    <Row>
                        <Col md={6}>

                            <Form.Check type='radio' label='Cash On Delivery'
                                id='Cash' name='paymentMethod' value='Cash' checked onChange={(e) => setPaymentMethod
                                    (e.target.value)}>

                            </Form.Check>
                        </Col>
                        <Col md={6}>

                            <Form.Check type='radio' label='Pay Online'
                                id='Cash' name='paymentMethod' value='Razorpay' onChange={(e) => setPaymentMethod
                                    (e.target.value)}></Form.Check>
                        </Col>
                    </Row>
                </Form.Group>
                <br />
                <Button type='submit' variant='dark' id='placeOrder' onClick={submitHandler}> Continue To Place Order</Button>
            </Form>
        </FormContainer >
    )
}

export default PaymentScreen
