import React from "react";

const ShippingPolicy = () => {
  return (
    <div>
      <p>Our Shipping Policy was last updated on 01-Aug-2022</p>
      <p>
        <strong>Interpretation and Definitions</strong>
      </p>
      <p>
        <strong>Interpretation</strong>
      </p>
      <p>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>
      <p>
        <strong>Definitions</strong>
      </p>
      <p>For the purposes of this Disclaimer:</p>
      <ul>
        <li>
          <strong>"Company"</strong> (referred to as either "the Company", "We",
          "Us" or "Our" in this Disclaimer) refers to Huzaifa Software Solutions
          Private Limited.
        </li>
        <li>
          <strong>"Goods"</strong> refers to the items offered for sale on the
          Service.
        </li>
        <li>
          <strong>"Orders"</strong> means a request by You to purchase Goods
          from Us.
        </li>
        <li>
          <strong>"Service"</strong> refers to the Website.
        </li>
        <li>
          <strong>"Website"</strong> refers to retailcenter.io, accessible from
          https://www.retailcenter.io
        </li>
        <li>
          <strong>"You"</strong> means the individual accessing the Service, or
          the company, or other legal entity on behalf of which such individual
          is accessing or using the Service, as applicable.
        </li>
      </ul>
      <p>
        Thank you for visiting and shopping at retailcenter. The following terms
        and conditions constitute our Shipping Policy. This Shipping was
        generated by{" "}
        <a href="https://www.termsfeed.com/blog/sample-shipping-policy-template/">
          TermsFeed Shipping Policy Template
        </a>
      </p>
      <p>
        <strong>Domestic Shipping Policy</strong>
      </p>
      <p>
        <strong>Shipment processing times</strong>
      </p>
      <p>
        All Orders are processed within 2-3 business days. Orders are not
        shipped or delivered on weekends or holidays.
      </p>
      <p>
        If We are experiencing a high volume of orders, shipments may be delayed
        by a few days. Please allow additional days in transit for delivery. If
        there will be a significant delay in shipment of Your Order, We will
        contact You via email or telephone.
      </p>
      <p>
        <strong>Shipping rates & delivery estimates</strong>
      </p>
      <p>
        Shipping charges for Your Orders will be calculated and displayed at
        checkout.
      </p>
      <ul>
        <li>
          <p>
            <strong>Shipping method: Bluedart Standard</strong>
          </p>
          <p>Shipment cost: Free</p>
          <p>Estimated delivery time: 3-5 business days</p>
        </li>
        <li>
          <p>
            <strong>Shipping method: Bluedart Two Days</strong>
          </p>
          <p>Shipment cost: ₹13</p>
          <p>Estimated delivery time: 2 business days</p>
        </li>
        <li>
          <p>
            <strong>Shipping method: Bluedart Standard</strong>
          </p>
          <p>Shipment cost: ₹20</p>
          <p>Estimated delivery time: 1-2 business days</p>
        </li>
      </ul>
      <p>
        Overnight delivery is only available for Orders with delivery addresses
        within the continental United States.
      </p>
      <p>Delivery delays can occasionally occur.</p>
      <p>
        <strong>Shipment to P.O. boxes or APO/FPO addresses</strong>
      </p>
      <p>
        Huzaifa Software Solutions Private Limited ships to addresses within the
        india.
      </p>
      <p>
        <strong>Shipment confirmation & Order tracking</strong>
      </p>
      <p>
        You will receive a Shipment Confirmation Email once Your Order has
        shipped containing your tracking number(s). The tracking number will be
        active within 24 hours.
      </p>
      <p>
        <strong>Customs, Duties and Taxes</strong>
      </p>
      <p>
        retailcenter.io is not responsible for any customs and taxes applied to
        Your Order. All fees imposed during or after shipping are the
        responsibility of the customer (tariffs, taxes)
      </p>
      <p>
        <strong>Damages</strong>
      </p>
      <p>
        retailcenter.io is not liable for any products damaged or lost during
        shipping. If You received Your Order damaged, please contact the
        shipment carrier to file a claim.
      </p>
      <p>
        Please save all packaging materials and damaged goods before filing a
        claim.
      </p>
      <p>Our Shipping Policy was last updated on 01/08/2022</p>
      <p>
        <strong>Interpretation and Definitions</strong>
      </p>
      <p>
        <strong>Interpretation</strong>
      </p>
      <p>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>
      <p>
        <strong>Definitions</strong>
      </p>
      <p>For the purposes of this Disclaimer:</p>
      <ul>
        <li>
          <strong>"Company"</strong> (referred to as either "the Company", "We",
          "Us" or "Our" in this Disclaimer) refers to Huzaifa Software Solutions
          Private Limited.
        </li>
        <li>
          <strong>"Goods"</strong> refers to the items offered for sale on the
          Service.
        </li>
        <li>
          <strong>"Orders"</strong> means a request by You to purchase Goods
          from Us.
        </li>
        <li>
          <strong>"Service"</strong> refers to the Website.
        </li>
        <li>
          <strong>"Website"</strong> refers to retailcenter.io, accessible from
          https://www.retailcenter.io
        </li>
        <li>
          <strong>"You"</strong> means the individual accessing the Service, or
          the company, or other legal entity on behalf of which such individual
          is accessing or using the Service, as applicable.
        </li>
      </ul>
      <p>
        Thank you for visiting and shopping at retailcenter.io. The following
        terms and conditions constitute our Shipping Policy. This Shipping was
        generated by{" "}
        <a href="https://www.termsfeed.com/">
          TermsFeed Shipping Policy Generator
        </a>
      </p>
      <p>
        <strong>Domestic Shipping Policy</strong>
      </p>
      <p>
        <strong>Shipment processing times</strong>
      </p>
      <p>
        All Orders are processed within 2-3 business days. Orders are not
        shipped or delivered on weekends or holidays.
      </p>
      <p>
        If We are experiencing a high volume of orders, shipments may be delayed
        by a few days. Please allow additional days in transit for delivery. If
        there will be a significant delay in shipment of Your Order, We will
        contact You via email or telephone.
      </p>
      <p>
        <strong>Shipping rates & delivery estimates</strong>
      </p>
      <p>
        Shipping charges for Your Orders will be calculated and displayed at
        checkout.
      </p>
      <ul>
        <li>
          <p>
            <strong>Shipping method: Bluedart Standard</strong>
          </p>
          <p>Shipment cost: Free</p>
          <p>Estimated delivery time: 3-5 business days</p>
        </li>
        <li>
          <p>
            <strong>Shipping method: Bluedart Two Days</strong>
          </p>
          <p>Shipment cost: ₹12</p>
          <p>Estimated delivery time: 2 business days</p>
        </li>
        <li>
          <p>
            <strong>Shipping method: Bluedart Standard</strong>
          </p>
          <p>Shipment cost: ₹19</p>
          <p>Estimated delivery time: 1-2 business days</p>
        </li>
      </ul>
      <p>
        Overnight delivery is only available for Orders with delivery addresses
        within the continental United States.
      </p>
      <p>Delivery delays can occasionally occur.</p>
      <p>
        <strong>Shipment to P.O. boxes or APO/FPO addresses</strong>
      </p>
      <p>
        retailcenter.io ships to addresses within the U.S., U.S. Territories,
        and APO/FPO/DPO addresses.
      </p>
      <p>
        <strong>Shipment confirmation & Order tracking</strong>
      </p>
      <p>
        You will receive a Shipment Confirmation Email once Your Order has
        shipped containing your tracking number(s). The tracking number will be
        active within 24 hours.
      </p>
      <p>
        <strong>Customs, Duties and Taxes</strong>
      </p>
      <p>
        retailcenter.io is not responsible for any customs and taxes applied to
        Your Order. All fees imposed during or after shipping are the
        responsibility of the customer (tariffs, taxes)
      </p>
      <p>
        <strong>Damages</strong>
      </p>
      <p>
        retailcenter.io is not liable for any products damaged or lost during
        shipping. If You received Your Order damaged, please contact the
        shipment carrier to file a claim.
      </p>
      <p>
        Please save all packaging materials and damaged goods before filing a
        claim.
      </p>
      <p>
        <strong>Contact Us</strong>
      </p>
      <p>
        If you have any questions about this Shipping Policy, You can contact
        Us:
      </p>
      <ul>
        <li>
          By visiting this page on our website:
          https://www.retailcenter.io/aboutus#contact
        </li>
        <li>By sending us an email: mufeez@mufeed.co.in</li>
      </ul>
      <p></p>
    </div>
  );
};

export default ShippingPolicy;
