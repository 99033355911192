import { initializeApp } from 'firebase/app'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB9HhQ9rvLvF6LErtM13mS_mZYKovVEBds",
  authDomain: "retail-center.firebaseapp.com",
  projectId: "retail-center",
  storageBucket: "retail-center.appspot.com",
  messagingSenderId: "377965025371",
  appId: "1:377965025371:web:4ad19956e1567df772c77e",
  measurementId: "G-20GVQG803V"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig)
export default firebase
