import React, { useEffect } from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'
import { Line } from 'react-chartjs-2'
import { saleReport } from '../actions/orderActions'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

export function SaleReportChart({ history }) {
  const dispatch = useDispatch()
  const productSaleRt = useSelector((state) => state.productSaleReport)
  const { productSaleReport, loading, error } = productSaleRt
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo && userInfo.role.includes('admin')) {
      dispatch(saleReport())
    } else {
      history.push('/login')
    }
  }, [dispatch, history, userInfo])

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='warning'>{error}</Message>
      ) : (
        <>
          <p className='DashboardHeading'>Daily Sales Report</p>
          <Line
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
              },
            }}
            data={{
              labels: productSaleReport.map((product) => product._id),
              datasets: [
                {
                  label: 'TotalPrice',
                  data: productSaleReport.map((product) => product.totalPrice),
                  borderColor: 'rgb(255, 99, 132)',
                  backgroundColor: 'rgba(255, 99, 132, 0.5)',
                  fill: false,
                  lineTension: 0.3,
                },
              ],
            }}
          />
        </>
      )}
    </>
  )
}
