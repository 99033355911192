import { React } from 'react'
import Carousel from 'react-multi-carousel'
import { Link } from 'react-router-dom'
import 'react-multi-carousel/lib/styles.css'
import { Image } from 'react-bootstrap'
import Message from './Message'

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  laptop: {
    breakpoint: { max: 1024, min: 768 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 768, min: 425 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 425, min: 0 },
    items: 1,
  },
}

const ProductCarousel = ({ error, banners }) => {
  return error ? (
    <Message variant='warning'>{error}</Message>
  ) : (
    <div className='mb-1 mt-1' >
      <Carousel
        autoPlay
        autoPlaySpeed={8000}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        keyBoardControl={true}
        customTransition='all .5'
        transitionDuration={500}
        removeArrowOnDeviceType={['tablet', 'mobile']}
        dotListClass='custom-dot-list-style'
        itemClass='carousel-item-padding-40-px'
        minimumTouchDrag={80}
        swipeable={true}
        draggable={true}

      >

        {banners.map((images) => (

          <Link key={images._id} to={`${images.route}`}>

            <Image className='carouselImage' src={images.image} alt={'banner Image'} />
          </Link>

        ))}

      </Carousel>
    </div>
  )
}

export default ProductCarousel
