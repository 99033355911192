import axios from "axios";
import React from "react";
import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import Bundle from "../components/Bundle";
import env from 'dotenv'

env.config()
const storeId = process.env.REACT_APP_STORE_ID;
const BundleScreen = ({ history }) => {
  const [bundles, setBundles] = useState();

  useEffect(() => {
    const getBundles = async () => {
      const { data } = await axios.get(`/api/bundle/store/${storeId}`);
      setBundles(data);
    };
    getBundles();
  }, []);

  return (
    <div>
      {bundles && (
        <Row>
          {bundles.map((bundle, index) => (
            <Col key={index} xs={12} md={6} lg={6} xl={1.5}>
              <Bundle history={history} bundle={bundle} />
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
};

export default BundleScreen;
