const categories = [
  {
    id: 1,
    name: 'select',
    image: 'https://mufeed-images.s3.ap-south-1.amazonaws.com/kirana.png',
    subCategories: [
      {
        id: 0,
        name: 'select',
        image: '/img/subcategoryImage/daals.jpg',
      },
    ],
  },
  {
    id: 0,
    name: 'Kirana',
    image: 'https://mufeed-images.s3.ap-south-1.amazonaws.com/kirana.png',
    subCategories: [
      {
        id: 0,
        name: 'select',
        image: '/img/subcategoryImage/daals.jpg',
      }, {
        id: 1,
        name: 'daals',
        image: '/img/subcategoryImage/daals.jpg',
      },
      {
        id: 2,
        name: 'rice',
        image: '/img/subcategoryImage/rice.jpg',
      },
      {
        id: 3,
        name: 'flours',
        image: '/img/subcategoryImage/flours.jpg',
      },
      {
        id: 5,
        name: 'oil',
        image: '/img/subcategoryImage/oil.jpg',
      },
      {
        id: 6,
        name: 'ghee',
        image: '/img/subcategoryImage/ghee.jpg',
      },
      {
        id: 7,
        name: 'sugar',
        image: '/img/subcategoryImage/sugar.jpg',
      },
      {
        id: 9,
        name: 'masala',
        image: '/img/subcategoryImage/masala.jpg',
      },
      {
        id: 11,
        name: 'others',
        image: '/img/subcategoryImage/others.jpg',
      },
    ],
  },
  {
    id: 119,
    name: 'Vegetables',
    image: 'https://mufeed-images.s3.ap-south-1.amazonaws.com/tomato.jpg',
    subCategories: [

      {
        id: 40,
        name: 'Green',
        image: '/img/subcategoryImage/sofas.jpg',
      },
      {
        id: 41,
        name: 'Root ',
        image: '/img/subcategoryImage/beds.jpg',
      }
      ,
      {
        id: 42,
        name: 'Leafy ',
        image: '/img/subcategoryImage/tables.jpg',
      },
      {
        id: 43,
        name: 'others',
        image: '/img/subcategoryImage/others.jpg',
      }

    ],

  },
  {
    id: 120,
    name: 'PersonalCare',
    image: 'https://mufeed-images.s3.ap-south-1.amazonaws.com/personalCare.jpg',
    subCategories: [

      {
        id: 40,
        name: 'Soaps',
        image: '/img/subcategoryImage/sofas.jpg',
      },
      {
        id: 41,
        name: 'Shampoos',
        image: '/img/subcategoryImage/beds.jpg',
      }
      ,
      {
        id: 42,
        name: 'Conditioners',
        image: '/img/subcategoryImage/tables.jpg',
      }
      ,
      {
        id: 43,
        name: 'Hair Oils',
        image: '/img/subcategoryImage/chairs.jpg',
      }
    ],
  },
  {
    id: 130,
    name: 'Snacks',
    image: 'https://mufeed-images.s3.ap-south-1.amazonaws.com/snacks.png',
    subCategories: [


      {
        id: 41,
        name: 'namkeen',
        image: '/img/subcategoryImage/beds.jpg',
      }
      ,
      {
        id: 43,
        name: 'biscuits',
        image: '/img/subcategoryImage/chairs.jpg',
      }, {
        id: 44,
        name: 'chocolates',
        image: '/img/subcategoryImage/chairs.jpg',
      },
      {
        id: 45,
        name: 'others',
        image: '/img/subcategoryImage/chairs.jpg',
      }

    ],
  },
  {
    id: 140,
    name: 'Household',
    image: 'https://mufeed-images.s3.ap-south-1.amazonaws.com/household.png',
    subCategories: [

      {
        id: 40,
        name: 'Cleaning',
        image: '/img/subcategoryImage/sofas.jpg',
      },
      {
        id: 41,
        name: 'Laundry',
        image: '/img/subcategoryImage/beds.jpg',
      }
      ,
      {
        id: 43,
        name: 'Kitchen',
        image: '/img/subcategoryImage/chairs.jpg',
      }
      ,
      {
        id: 44,
        name: 'Bathroom',
        image: '/img/subcategoryImage/chairs.jpg',
      }
      ,
      {
        id: 45,
        name: 'Detergents',
        image: '/img/subcategoryImage/chairs.jpg',
      }
      ,
      {
        id: 46,
        name: 'Disinfectants',
        image: '/img/subcategoryImage/chairs.jpg',
      }
      ,
      {
        id: 47,
        name: 'Repellents',
        image: '/img/subcategoryImage/chairs.jpg',
      }
      ,
      {
        id: 48,
        name: 'others',
        image: '/img/subcategoryImage/chairs.jpg',
      }
    ],
  },
  {
    id: 150,
    name: 'Dairy',
    image: 'https://mufeed-images.s3.ap-south-1.amazonaws.com/dairy.png',
    subCategories: [

      {
        id: 40,
        name: 'Milk',
        image: '/img/subcategoryImage/sofas.jpg',
      },
      {
        id: 41,
        name: 'Butter',
        image: '/img/subcategoryImage/beds.jpg',
      }
      ,
      {
        id: 43,
        name: 'Dahi',
        image: '/img/subcategoryImage/chairs.jpg',
      }
      ,
      {
        id: 45,
        name: 'Paneer',
        image: '/img/subcategoryImage/chairs.jpg',
      }
      ,
    ],
  },
  {
    id: 160,
    name: 'Fruits',
    image: 'https://mufeed-images.s3.ap-south-1.amazonaws.com/fruits.jpg',
    subCategories: [

      {
        id: 46,
        name: 'Dates',
        image: '/img/subcategoryImage/chairs.jpg',
      },
      {
        id: 40,
        name: 'Citrus',
        image: '/img/subcategoryImage/sofas.jpg',
      },
      {
        id: 41,
        name: 'Berries',
        image: '/img/subcategoryImage/beds.jpg',
      }
      ,
      {
        id: 42,
        name: 'Melons',
        image: '/img/subcategoryImage/tables.jpg',
      }
      ,
      {
        id: 43,
        name: 'Pomegranates',
        image: '/img/subcategoryImage/chairs.jpg',
      }
      ,
      {
        id: 44,
        name: 'Papayas',
        image: '/img/subcategoryImage/chairs.jpg',
      }
      ,
      {
        id: 45,
        name: 'Apples',
        image: '/img/subcategoryImage/chairs.jpg',
      }
      ,
      {
        id: 46,
        name: 'others',
        image: '/img/subcategoryImage/chairs.jpg',
      }
    ],
  },
  {
    id: 170,
    name: 'IceCream',
    image: 'https://mufeed-images.s3.ap-south-1.amazonaws.com/icecream.jpg',
    subCategories: [

      {
        id: 40,
        name: 'Vanilla',
        image: '/img/subcategoryImage/sofas.jpg',
      },
      {
        id: 41,
        name: 'Chocolate',
        image: '/img/subcategoryImage/beds.jpg',
      }
      ,
      {
        id: 42,
        name: 'Strawberry',
        image: '/img/subcategoryImage/tables.jpg',
      }
      ,
      {
        id: 43,
        name: 'Butterscotch',
        image: '/img/subcategoryImage/chairs.jpg',
      }
      ,
      {
        id: 44,
        name: 'Mango',
        image: '/img/subcategoryImage/chairs.jpg',
      }
      ,
      {
        id: 45,
        name: 'Pista',
        image: '/img/subcategoryImage/chairs.jpg',
      }
      ,
      {
        id: 46,
        name: 'kulfi',
        image: '/img/subcategoryImage/chairs.jpg',
      },
      {
        id: 47,
        name: 'others',
        image: '/img/subcategoryImage/chairs.jpg',

      }
    ],
  }
]

export default categories
