import { React, useEffect, useState } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { Col } from 'react-bootstrap'
import axios from 'axios'
import Product from '../Product'
import Message from '../Message'
import env from 'dotenv'

env.config()
const storeId = process.env.REACT_APP_STORE_ID;

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 425 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 425, min: 0 },
    items: 3,
  },
}

const ProductSlider = () => {

  const [TopSelling, setTopSelling] = useState([])
  const [error, setError] = useState(false)

  useEffect(() => {
    axios
      .get(`/api/orders/store/${storeId}/topselling`)
      .then((res) => {
        setTopSelling(res.data)
      })
      .catch((err) => {
        console.log(err)
        setError(true)
      })
  }, [])

  return error ? (
    <Message variant='warning'>{error}</Message>
  ) : (
    <div >
      <h1 className='carouselHeading' >  TOP SELLINGS </h1>
      <Carousel
        autoPlay
        autoPlaySpeed={8000}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        keyBoardControl={true}
        customTransition='all .5'
        transitionDuration={500}
        removeArrowOnDeviceType={['tablet', 'mobile']}
        dotListClass='custom-dot-list-style'
        itemClass='carousel-item-padding-40-px'
        minimumTouchDrag={80}
        swipeable={true}
        draggable={true}

      >
        {TopSelling.map((product, index) => index < 8 && (

          <Col key={product._id} xs={12} sm={12} md={12} lg={12} xl={12}>
            <Product product={product} id='product' />
          </Col>

        ))}

      </Carousel>
    </div>
  )
}

export default ProductSlider
