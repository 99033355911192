import React from 'react'

const Category = ({ history, category }) => {
  const submitHandler = (e) => {
    e.preventDefault()
    if (category.name.trim()) {
      history.push(`/category/${category.name}`)
    } else {
      history.push('/')
    }
  }

  return (
    <>
      <div onClick={submitHandler} className='zoom'>
        <div className='category-img'>
          <img src={category.image} alt='img' variant='top' />
        </div>
        <div className='category-btn'>
          <button className='btn' type='submit'>
            {category.name}
          </button>
        </div>
      </div>
    </>
  )
}

export default Category