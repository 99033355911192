import React, { useState, useEffect, Fragment, } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { BsCheckSquareFill } from "react-icons/bs";
import axios from 'axios'
/*	import env from 'dotenv' */


/* env.config()
axios.defaults.baseURL = process.env.REACT_APP_API_SERVER
const store = process.env.REACT_APP_STORE_ID */
const OnBoardStoreScreen = ({ history }) => {



	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const productDetails = useSelector((state) => state.productDetails)
	const { loading, error } = productDetails


	const [name, setName] = useState('')
	const [address, setAddress] = useState('')
	const [city, setCity] = useState('')
	const [company, setCompany] = useState('')
	const [country, setCountry] = useState('')
	const [pinCode, setPinCode] = useState('')
	const [gst, setGst] = useState('')
	const [logo, setLogo] = useState('')

	const [redirect, setRedirect] = useState(false);
	const [modalShow, setModalShow] = React.useState(false);

	useEffect(() => {
		if (!userInfo) {
			history.push('/login?redirect=onboard')
		}
	})



	const submitHandler = (e) => {
		e.preventDefault()
		e.target.reset();
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}
		const data = {
			'name': name,
			'logo': logo,
			'address': address,
			'company': company,
			'pinCode': pinCode,
			'city': city,
			'country': country,
			'gstNumber': gst
		}
		axios
			.post(`/api/config`, data, config)
			.catch((err) => {
				console.log(err)
			}
			)

		setModalShow(true)
		setName('')
		setAddress('')
		setCity('')
		setCompany('')
		setCountry('')
		setPinCode('')
		setGst('')
		setLogo('')
	}



	function MyVerticallyCenteredModal(props) {
		return (
			<Modal
				style={{ color: '#000' }}
				{...props}
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header style={{ backgroundColor: 'white' }} closeButton>
					<Modal.Title style={{ color: 'green' }} id="contained-modal-title-vcenter">
						Data Successfully Stored <BsCheckSquareFill />
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ backgroundColor: 'white' }}>
					<h4>
						Our Team member will Contact You Soon
					</h4>
				</Modal.Body>
				<Modal.Footer style={{ backgroundColor: 'white' }}>
					<Button onClick={props.onHide}>Close</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	return (
		<>
			{redirect && (
				<Fragment>
					<Redirect to='/' />
				</Fragment>
			)}
			<div>
				{loading ? (
					<Loader />
				) : error ? (
					<Message variant='warning'>{error}</Message>
				) : (
					<>
						<Button variant='secondary'>
							<MdOutlineArrowBackIosNew />
							<Link to='/' style={{ color: "white" }}> Home</Link>
						</Button>
						<Form onSubmit={submitHandler}>
							<Row className="mb-1">
								<Col>
									<h4 style={heading}> On Boardding Process</h4>
									<Col >
										<Form.Group className="mb-3" controlId="number">
											<Form.Label>Mobile Number <span style={{ color: 'red' }}>*</span></Form.Label>
											<Form.Control placeholder="Enter Your Number" required value={name} onChange={(e) => setName(e.target.value)} />
										</Form.Group>
									</Col>
									<Col >
										<Form.Group className="mb-3" controlId="address">
											<Form.Label>Address <span style={{ color: 'red' }}>*</span></Form.Label>
											<Form.Control placeholder="Enter Your Address" required value={address} onChange={(e) => setAddress(e.target.value)} />
										</Form.Group>
									</Col>
									<Col>
										<Form.Group className="mb-3" controlId="City">
											<Form.Label>City <span style={{ color: 'red' }}>*</span></Form.Label>
											<Form.Control type='text' placeholder="Enter City" required value={city} onChange={(e) => setCity(e.target.value)} />
										</Form.Group>
									</Col>
									<Col>
										<Form.Group className="mb-3" controlId="Company">
											<Form.Label>Company <span style={{ color: 'red' }}>*</span></Form.Label>
											<Form.Control type='text' placeholder=" Enter Your Company Name" required value={company} onChange={(e) => setCompany(e.target.value)} />
										</Form.Group>
									</Col>
									<Col>
										<Form.Group className="mb-3" controlId="Country">
											<Form.Label>Country <span style={{ color: 'red' }}>*</span></Form.Label>
											<Form.Control type='text' placeholder="Enter Country" required value={country} onChange={(e) => setCountry(e.target.value)} />
										</Form.Group>
									</Col>
									<Col>
										<Form.Group className="mb-3" controlId="PinCode">
											<Form.Label>PinCode <span style={{ color: 'red' }}>*</span></Form.Label>
											<Form.Control type='text' placeholder="Enter PinCode" required value={pinCode} onChange={(e) => setPinCode(e.target.value)} />
										</Form.Group>
									</Col>
									<Col>
										<Form.Group className="mb-3" controlId="gstNumber">
											<Form.Label>GST Number <span style={{ color: 'red' }}>*</span></Form.Label>
											<Form.Control type='text' placeholder="Enter GST Number" required value={gst} onChange={(e) => setGst(e.target.value)} />
										</Form.Group>
									</Col>
									<Col>
										<Form.Group className="mb-3" controlId="Logo">
											<Form.Label>Logo <span style={{ color: 'red' }}>*</span></Form.Label>
											<Form.Control type='text' placeholder="Enter Logo Preference" required value={logo} onChange={(e) => setLogo(e.target.value)} />
										</Form.Group>
									</Col>
								</Col>
							</Row>
							<Button variant="dark" type="submit">
								Submit
							</Button>
						</Form>

						<MyVerticallyCenteredModal
							show={modalShow}
							onHide={() => setRedirect(true) && setModalShow(false)}
						/>

					</>
				)
				}

			</div >
		</>
	)
}

export default OnBoardStoreScreen

const heading = {
	textAlign: 'center',
	color: '#000'
}