import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { useSelector } from 'react-redux'
import Loader from '../components/Loader'
import axios from 'axios'

ChartJS.register(ArcElement, Tooltip, Legend)

export function OrdersDoughnut() {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const [orderStatus, setOrderStatus] = useState({})



  useEffect(() => {
    const config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  }
    axios
      .get('/api/orders/status', config)
      .then((res) => {
        setOrderStatus(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [userInfo.token])

  if (Object.keys(orderStatus).length === 0) {
    return <Loader />
  } else {
    return (
      <>
        <p className='DashboardHeading'>Order Details</p>
        <Doughnut
          data={{
            responsive: true,
            maintainAspectRatio: false,
            labels: ['New', 'Paid', 'Delivered'],
            datasets: [
              {
                data: [orderStatus.newOrdersCount, orderStatus.paidOrdersCount, orderStatus.deliveredOrdersCount],
                backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(127,255,0,0.2)'],
                borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)'],
                borderWidth: 1,
              },
            ],
          }}
        />
      </>
    )
  }
}
