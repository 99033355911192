import React from 'react'
import { Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { BsCardChecklist, BsBoxSeam, BsPerson } from 'react-icons/bs'
import { AiOutlineHome, AiOutlineShoppingCart, AiOutlinePartition } from 'react-icons/ai'
import { BiFile } from "react-icons/bi"
import SearchBox from './SearchBox'
import { logout } from '../actions/userActions'

const Header = () => {
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  const storeConfig = useSelector((state) => state.storeConfig);
  const { store } = storeConfig;

  const logoutHandler = () => {
    dispatch(logout())
  }

  return (
    <>
      {userInfo && userInfo.role.includes('superAdmin') ? (
        <header className='headerShadow' style={{ position: 'sticky', top: '0', zIndex: '2' }}>
          <Navbar bg='dark' className='navbar-fixed-top' variant='dark' expand='md' collapseOnSelect>
            <Container>
              <LinkContainer to='/'>
                <Navbar.Brand>
                  <i className='fas fa-store'></i>{store.name}
                </Navbar.Brand>
              </LinkContainer>
              <Navbar.Toggle aria-controls='basic-navbar-nav' />
              <Navbar.Collapse id='basic-navbar-nav'>
                <Nav className=' mr-auto'>
                  <LinkContainer to='/onboard'>
                    <Nav.Link>
                      <AiOutlinePartition /> OnBoard Store
                    </Nav.Link>
                  </LinkContainer>
                  <LinkContainer to='/superadmin/storelist'>
                    <Nav.Link>
                      <i className='fas fa-th-list'></i> Store list
                    </Nav.Link>
                  </LinkContainer>
                </Nav>
                <Nav>
                  {userInfo ? (
                    <NavDropdown title={userInfo.name} id='username'>
                      <LinkContainer to='/profile'>
                        <NavDropdown.Item>
                          <i className='fas fa-user-circle'></i> profile
                        </NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to='/myorders' id='myorders'>
                        <NavDropdown.Item>
                          <i className='fas fa-list'></i> My Orders
                        </NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to='/login' id='LogOut'>
                        <NavDropdown.Item onClick={logoutHandler}>
                          <i className='fas fa-sign-out-alt'></i> Logout
                        </NavDropdown.Item>
                      </LinkContainer>
                    </NavDropdown>
                  ) : (
                    <LinkContainer to='/login' id='Login'>
                      <Nav.Link>
                        <i className='fas fa-user'></i>Sign In
                      </Nav.Link>
                    </LinkContainer>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </header>
      ) : (
        <>
          {userInfo && userInfo.role.includes('admin') ? (
            <header className='headerShadow' style={{ position: 'sticky', top: '0', zIndex: '2' }}>
              <Navbar bg='dark' className='navbar-fixed-top' variant='dark' expand='md' collapseOnSelect>
                <Container>
                  <LinkContainer to='/'>
                    <Navbar.Brand>
                      <i className='fas fa-store'></i>{store.name}
                    </Navbar.Brand>
                  </LinkContainer>
                  <Navbar.Toggle aria-controls='basic-navbar-nav' />
                  <Navbar.Collapse id='basic-navbar-nav'>
                    <Nav className=' mr-auto'>
                      <LinkContainer to='/admin/dashboard'>
                        <Nav.Link>
                          <i className='fas fa-tachometer-alt'></i> Dashboard
                        </Nav.Link>
                      </LinkContainer>

                      <LinkContainer to='/admin/userlist'>
                        <Nav.Link>
                          <i className='fas fa-users'></i> Users
                        </Nav.Link>
                      </LinkContainer>
                      <LinkContainer to='/cart' id='cart'>
                        <Nav.Link>
                          <div className='cart-item'>
                            <h6>{cartItems.length}</h6>
                          </div>
                          <i className='fas fa-shopping-cart'></i> Cart
                        </Nav.Link>
                      </LinkContainer>
                      <LinkContainer to='/bundle' id='bundle'>
                        <Nav.Link>
                          <i className='fas fa-box'></i> Bundle
                        </Nav.Link>
                      </LinkContainer>
                      <LinkContainer to='/admin/productlist'>
                        <Nav.Link>
                          <i className='fas fa-shopping-basket'></i> Products
                        </Nav.Link>
                      </LinkContainer>
                      <LinkContainer to='/admin/orderlist'>
                        <Nav.Link>
                          <i className='fas fa-th-list'></i> Orders
                        </Nav.Link>
                      </LinkContainer>
                      <LinkContainer to='/admin/invoices'>
                        <Nav.Link>
                          < BiFile size={20} />Invoice
                        </Nav.Link>
                      </LinkContainer>
                      <LinkContainer to='/admin/cartscreen'>
                        <Nav.Link>
                          <i className='fas fa-barcode'></i> POS
                        </Nav.Link>
                      </LinkContainer>
                    </Nav>

                    <Nav>
                      {userInfo ? (
                        <NavDropdown title={userInfo.name} id='username'>
                          <LinkContainer to='/profile'>
                            <NavDropdown.Item>
                              <i className='fas fa-user-circle'></i> profile
                            </NavDropdown.Item>
                          </LinkContainer>
                          <LinkContainer to='/myorders' id='myorders'>
                            <NavDropdown.Item>
                              <i className='fas fa-list'></i> My Orders
                            </NavDropdown.Item>
                          </LinkContainer>
                          <LinkContainer to='/login' id='LogOut'>
                            <NavDropdown.Item onClick={logoutHandler}>
                              <i className='fas fa-sign-out-alt'></i> Logout
                            </NavDropdown.Item>
                          </LinkContainer>
                        </NavDropdown>
                      ) : (
                        <LinkContainer to='/login' id='Login'>
                          <Nav.Link>
                            <i className='fas fa-user'></i>Sign In
                          </Nav.Link>
                        </LinkContainer>
                      )}
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </header>
          ) : (window.innerWidth) < 450 ? (
            < >
              <header className='headerShadow' style={{ position: 'sticky', top: '0', zIndex: '5' }}>
                <Navbar bg='dark' className=' justify-content-evenly' stickey="top" variant='dark'>
                  <Container>
                    <LinkContainer to='/'>
                      <Navbar.Brand>  <i className='fas fa-store'></i> <span style={{ fontSize: '1.2rem' }}>{store.name}</span></Navbar.Brand>
                    </LinkContainer>

                    <Route render={({ history }) => <SearchBox history={history} />} />
                  </Container>
                </Navbar>
              </header>


              <footer >
                <Navbar className='footerNavbar justify-content-evenly' fixed="bottom" style={{ padding: '0.4rem 0 0.1rem 0', zIndex: '2' }} >



                  <LinkContainer to='/' id='Home' style={{ justifyContent: 'space-between' }} >
                    <Nav.Link className='allicons'>
                      <div className='iconcenter'>
                        <AiOutlineHome size={25} />
                      </div>
                      <div className='iconcenter'>
                        Home
                      </div>
                    </Nav.Link>
                  </LinkContainer>

                  <LinkContainer to='/cart' id='cart' >
                    <Nav.Link className='allicons'>
                      <div className='cart-item'>
                        <h6>{cartItems.length}</h6>
                      </div>
                      <div className='iconcenter'>
                        <AiOutlineShoppingCart size={25} />
                      </div>
                      <div className='iconcenter'>
                        Cart
                      </div>
                    </Nav.Link>
                  </LinkContainer>

                  <LinkContainer to='/myorders' id='category' >
                    <Nav.Link className='allicons'>
                      <div className='iconcenter'>
                        <BsCardChecklist size={25} />
                      </div>
                      <div className='iconcenter'>
                        orders
                      </div>

                    </Nav.Link>
                  </LinkContainer>

                  <LinkContainer to='/bundle' id='bundle' >
                    <Nav.Link className='allicons'>
                      <div className='iconcenter'>
                        <BsBoxSeam size={25} />
                      </div>
                      <div className='iconcenter'>
                        Bundle
                      </div>
                    </Nav.Link>
                  </LinkContainer>

                  {userInfo ? (

                    <LinkContainer to='/profile' id='Login'>
                      <Nav.Link className='allicons'>
                        <div className='iconcenter'>
                          <BsPerson size={25} />
                        </div>
                        <div className='iconcenter'>
                          Profile
                        </div>
                      </Nav.Link>
                    </LinkContainer>
                  ) : (

                    <LinkContainer to='/login' id='Login'>
                      <Nav.Link className='allicons'>
                        <div className='iconcenter'>
                          <BsPerson size={25} />
                        </div>
                        <div className='iconcenter'>
                          Sign In
                        </div>
                      </Nav.Link>
                    </LinkContainer>
                  )}

                  {userInfo && userInfo.role.includes('admin') && (
                    <NavDropdown title='Admin' id='adminmenu'>
                      <LinkContainer to='/admin/userlist' id='users'>
                        <NavDropdown.Item>users</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to='/admin/productlist' id='products'>
                        <NavDropdown.Item>Products</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to='/admin/orderlist' id='orders'>
                        <NavDropdown.Item>Orders</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to='/admin/cartscreen' id='POS'>
                        <NavDropdown.Item>POS</NavDropdown.Item>
                      </LinkContainer>
                    </NavDropdown>
                  )}
                </Navbar>
              </footer>
            </>

          ) : (<header className='headerShadow' style={{ position: 'sticky', top: '0', zIndex: '2' }}>
            <Navbar bg='dark' variant='dark' expand='md' collapseOnSelect>
              <Container>
                <LinkContainer to='/'>
                  <Navbar.Brand><i className='fas fa-store'></i>  {store.name} </Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls='basic-navbar-nav' />
                <Navbar.Collapse id='basic-navbar-nav'>
                  <Route render={({ history }) => <SearchBox history={history} />} />
                  <div className='nav'>
                    <Nav className='ml-auto '>
                      <LinkContainer to='/cart' id='cart'>
                        <Nav.Link>
                          <div className='cart-item'>
                            <h6>{cartItems.length}</h6>
                          </div>
                          <i className='fas fa-shopping-cart'></i> Cart
                        </Nav.Link>
                      </LinkContainer>
                      <LinkContainer to='/bundle' id='bundle'>
                        <Nav.Link>
                          <i className='fas fa-box'></i> Bundle
                        </Nav.Link>
                      </LinkContainer>
                      {userInfo ? (
                        <NavDropdown title={userInfo.name} id='username'>
                          <LinkContainer to='/profile'>
                            <NavDropdown.Item>profile</NavDropdown.Item>
                          </LinkContainer>
                          <LinkContainer to='/myorders' id='myorders'>
                            <NavDropdown.Item>My Orders</NavDropdown.Item>
                          </LinkContainer>
                          <LinkContainer to='/login' id='LogOut'>
                            <NavDropdown.Item onClick={logoutHandler}>Logout</NavDropdown.Item>
                          </LinkContainer>
                        </NavDropdown>
                      ) : (
                        <LinkContainer to='/login' id='Login'>
                          <Nav.Link>
                            <i className='fas fa-user'></i>Sign In
                          </Nav.Link>
                        </LinkContainer>
                      )}
                      {userInfo && userInfo.role.includes('admin') && (
                        <NavDropdown title='Admin' id='adminmenu'>
                          <LinkContainer to='/admin/userlist' id='users'>
                            <NavDropdown.Item>users</NavDropdown.Item>
                          </LinkContainer>
                          <LinkContainer to='/admin/productlist' id='products'>
                            <NavDropdown.Item>Products</NavDropdown.Item>
                          </LinkContainer>
                          <LinkContainer to='/admin/orderlist' id='orders'>
                            <NavDropdown.Item>Orders</NavDropdown.Item>
                          </LinkContainer>
                          <LinkContainer to='/admin/cartscreen' id='POS'>
                            <NavDropdown.Item>POS</NavDropdown.Item>
                          </LinkContainer>
                        </NavDropdown>
                      )}
                    </Nav>
                  </div>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </header>)}
        </>
      )}
    </>
  )
}

export default Header
